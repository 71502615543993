import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import EventCard from "./EventCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";

const SliderContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  margin: "auto",
  height: "fit-content",
  [theme.breakpoints.down("sm")]: {
    height: "fit-content",
  },
}));

const SliderEvent = ({ events }) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const slideStyle = {
    width: isXSmall ? "100%" : isSmall ? "80%" : isMedium ? "70%" : "60%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const cardStyle = {
    fontSize: isXSmall ? "0.75rem" : isSmall ? "0.875rem" : "1rem",
  };

  return (
    <SliderContainer>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {events.map((event) => (
          <SwiperSlide key={event.id} style={{ ...slideStyle }}>
            <EventCard event={event} style={{ ...cardStyle }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  );
};

export default SliderEvent;
