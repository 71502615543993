import React, { useState } from "react";
import PdfCard from "../component/PdfCard";
import AudioCard from "../component/AudioCard";
import VideoCard from "../component/VideoCard";
import { Grid, Box, IconButton, List, Badge } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import MovieIcon from "@mui/icons-material/Movie";
import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import staticPdfs from "./staticPdfs.json";
import PdfFilterForm from "../component/PdfFilterForm";
import PdfListItem from "../component/PdfListItem";
import AudioListItem from "../component/AudioListItem";
import VideoListItem from "../component/VideoListItem";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const IconWrapper = styled(DescriptionIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

// Function to normalize strings
const normalizeString = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const PdfGridPage = () => {
  const [filteredPdfs, setFilteredPdfs] = useState(staticPdfs);
  const [viewMode, setViewMode] = useState("grid"); // 'grid' or 'list'
  const [mediaType, setMediaType] = useState("pdf"); // 'pdf', 'audio', 'video'

  const handleFilter = (searchTerms) => {
    const { keyword, domaine } = searchTerms;

    console.log("les mots ");
    console.log({ keyword, domaine });
    const normalizedKeyword = normalizeString(keyword || "");
    const normalizedDomaine = normalizeString(domaine || "");

    const filtered = staticPdfs.filter((item) => {
      const normalizedTitle = normalizeString(item.title || "");
      const normalizedDescription = normalizeString(item.description || "");
      const normalizedPdfDomaine = normalizeString(item.domaine || "");

      const matchesKeyword =
        !normalizedKeyword ||
        normalizedTitle.includes(normalizedKeyword) ||
        normalizedDescription.includes(normalizedKeyword);

      const matchesDomaine =
        !normalizedDomaine || normalizedPdfDomaine.includes(normalizedDomaine);

      return matchesKeyword && matchesDomaine;
    });

    setFilteredPdfs(filtered);
  };

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  const toggleMediaType = (type) => {
    setMediaType(type);
  };

  function getLimitedDescription(description) {
    const maxLength = 150;

    // Check if the description exceeds the maximum length and truncate if necessary
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }

    return description;
  }

  const renderCards = (item) => {
    switch (mediaType) {
      case "audio":
        return <AudioCard audio={item} />;
      case "video":
        return <VideoCard video={item} />;
      case "pdf":
      default:
        return <PdfCard pdf={item} />;
    }
  };

  const renderLists = (item) => {
    switch (mediaType) {
      case "audio":
        return <AudioListItem audio={item} />;
      case "video":
        return <VideoListItem video={item} />;
      case "pdf":
      default:
        return <PdfListItem pdf={item} mediaType={mediaType} />;
    }
  };

  return (
    <Box style={{ minHeight: "100vh", paddingBottom: "30px" }}>
      <Navbar />
      <Banner />
      <PdfFilterForm onFilter={handleFilter} />
      <div style={{ height: "50px" }}></div>

      <Box
        display="flex"
        justifyContent="space-between"
        style={{ margin: "0 30px" }}
      >
        {/* Media Type Selection */}
        <Box>
          <IconButton onClick={() => toggleMediaType("pdf")}>
            <PictureAsPdfIcon
              color={mediaType === "pdf" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("audio")}>
            <AudiotrackIcon
              color={mediaType === "audio" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("video")}>
            <MovieIcon color={mediaType === "video" ? "primary" : "default"} />
          </IconButton>
        </Box>

        {/* View Mode Selection */}
        <Box>
          <IconButton onClick={() => toggleViewMode("grid")}>
            <ViewModuleIcon
              color={viewMode === "grid" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleViewMode("list")}>
            <ViewListIcon color={viewMode === "list" ? "primary" : "default"} />
          </IconButton>
        </Box>
      </Box>

      <div style={{ width: "fit-content", margin: "auto" }}>
        <StyledTypography component="p">
          <IconWrapper />
          {filteredPdfs.length} fiches disponibles
        </StyledTypography>
      </div>

      {/* Display content based on view mode */}
      {viewMode === "grid" ? (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{
            marginTop: "20px",
            width: "100%",
            maxWidth: 1280,
            margin: "auto",
            paddingRight: "30px",
          }}
        >
          {filteredPdfs.length ? (
            filteredPdfs.map((item, i) => (
              <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                {renderCards(item)}
              </Grid>
            ))
          ) : (
            <p>Aucune fiche ne correspond à votre recherche</p>
          )}
        </Grid>
      ) : (
        <List
          style={{
            width: "100%",
            maxWidth: 1280,
            margin: "auto",
            paddingRight: "30px",
          }}
        >
          {filteredPdfs.length ? (
            filteredPdfs.map((item, i) => renderLists(item))
          ) : (
            <p>Aucune fiche ne correspond à votre recherche</p>
          )}
        </List>
      )}
    </Box>
  );
};

export default PdfGridPage;
