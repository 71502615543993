// formationsReducer.js
import axios from "axios";

import { API_url } from "../GlobalVariables";
// Action Types
const FETCH_FORMATIONS_BEGIN = "FETCH_FORMATIONS_BEGIN";
const FETCH_FORMATIONS_SUCCESS = "FETCH_FORMATIONS_SUCCESS";
const FETCH_FORMATIONS_FAILURE = "FETCH_FORMATIONS_FAILURE";

// Action Creators
export const fetchFormationsBegin = () => ({
  type: FETCH_FORMATIONS_BEGIN,
});

export const fetchFormationsSuccess = (formations) => ({
  type: FETCH_FORMATIONS_SUCCESS,
  payload: { formations },
});

export const fetchFormationsFailure = (error) => ({
  type: FETCH_FORMATIONS_FAILURE,
  payload: { error },
});

// Reducer
const initialState = {
  formations: [],
  loading: false,
  error: null,
};

export default function formationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_FORMATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        formations: action.payload.formations,
      };
    case FETCH_FORMATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        formations: [],
      };
    default:
      return state;
  }
}

// Thunk
export function fetchFormations() {
  return async (dispatch) => {
    dispatch(fetchFormationsBegin());
    try {
      const response = await axios.get(`${API_url}/formation/formations`);
      // console.log(response.status);
      if (response.status === 200) {
        // console.log(response.data.Formation)
        dispatch(fetchFormationsSuccess(response.data.Formation));
      }
    } catch (error) {
      dispatch(fetchFormationsFailure(error.toString()));
    }
  };
}
