import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PdfPreviewModal from "./PdfPreviewModal";

const PdfListItem = ({ pdf, mediaType }) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getLimitedDescription(description) {
    const maxLength = isSmallScreen ? 80 : 140;

    // Check if the description exceeds the maximum length and truncate if necessary
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }

    return description;
  }

  return (
    <>
      <ListItem
        button
        onClick={handleClickOpen}
        divider
        style={{ minHeight: "70px" }}
      >
        <ListItemAvatar>
          <Avatar alt="PDF Icon" src="/pdf-icon.png" />{" "}
          {/* Placeholder for PDF icon */}
        </ListItemAvatar>
        <ListItemText
          primary={pdf.title}
          secondary={getLimitedDescription(pdf.description)}
        />
      </ListItem>
      <PdfPreviewModal pdfUrl={pdf.url} open={open} handleClose={handleClose} />
    </>
  );
};

export default PdfListItem;
