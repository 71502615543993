import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAlert } from "../AlertContext";
import {API_url} from "../GlobalVariables.js"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const DemandFormModal = ({ open, handleClose, user, isUser, dates }) => {
  const { id } = useParams();
  const [formValues, setFormValues] = useState({
    name: user ? user.name : "",
    mail: user ? user.mail : "",
    phone: user ? user.phone : "",
    formationId: id,
    isUser: isUser,
    user: user ? user._id : null,
    sessionId: dates?.[dates.length - 1]?._id ?? null,

  });
  const { showAlert } = useAlert();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    const url = `${API_url}/formation/demande`;
    try {
      const response = await axios.post(url, formValues);
      if (response.status === 201) {
        showAlert("Demande d'inscription envoyé !", "success");
        handleClose();
      } else if (response.status === 400) {
        showAlert(
          "Votre mail est deja associé a un compte, veuillez vous connecté !",
          "warning"
        );
        handleClose();
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande:", error);
      showAlert("Erreur lors de l'envoi de la demande", "error");
    }
  };

  const isDatePast = (dateStr) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    const [day, month, year] = dateStr.split('/');
    const date = new Date(year, month - 1, day); 

    return date < today;
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Envoyer une demande de formation
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          id="name"
          label="Nom"
          name="name"
          value={formValues.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="mail"
          label="Email"
          name="mail"
          value={formValues.mail}
          onChange={handleInputChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="phone"
          label="Téléphone"
          name="phone"
          value={formValues.phone}
          onChange={handleInputChange}
        />

        {/* Session Selector */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="session-label">Session</InputLabel>
          <Select
            labelId="session-label"
            id="session"
            name="sessionId"
            value={formValues.sessionId}
            label="Session"
            onChange={handleInputChange}
          >
            {dates.filter(date => !isDatePast(date.endDate)).map((date, index) => (
              <MenuItem key={index} value={date._id}>
                {date.startDate} - {date.endDate}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>
            Soumettre
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DemandFormModal;
