import React, { useState, useRef, useEffect } from "react";
import { ListItem, ListItemText, ListItemAvatar, Avatar, Dialog, DialogContent, Typography, Button, Box, Grid, useMediaQuery } from "@mui/material";
import MovieIcon from "@mui/icons-material/Movie";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from '@mui/material/styles';

const VideoListItem = ({ video }) => {
  const [open, setOpen] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const quizzes = video.quizzes || [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsQuizCompleted(false);
    setCurrentQuiz(0);
    setCorrectAnswers(0);
  };

  const handleAnswer = (index) => {
    if (index === quizzes[currentQuiz].answer) {
      setCorrectAnswers(correctAnswers + 1);
    }
    setCurrentQuiz(currentQuiz + 1);
    setShowQuiz(false);
    videoRef.current.play();
  };

  const handleVideoPause = () => {
    if (!showQuiz && currentQuiz < quizzes.length) {
      setShowQuiz(true);
    }
  };

  const handleVideoEnd = () => {
    setIsQuizCompleted(true);
  };

  const handleTimeUpdate = () => {
    if (currentQuiz < quizzes.length && videoRef.current.currentTime >= (currentQuiz + 1) * 10) {
      videoRef.current.pause();
    }
  };

  const handleFullScreenToggle = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  function getLimitedDescription(description) {
    const maxLength = isSmallScreen ? 80 : 140;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  return (
    <>
      <ListItem
        button
        onClick={handleClickOpen}
        divider
        style={{ minHeight: "70px" }}
      >
        <ListItemAvatar>
          <Avatar>
            <MovieIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={video.title}
          secondary={getLimitedDescription(video.description)}
        />
      </ListItem>

      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          style: {
            maxHeight: '80vh',
          },
        }}
      >
        <DialogContent>
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
            <Grid item>
              <Typography variant="h6">
                Score: {correctAnswers} / {quizzes.length}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Questions restantes: {quizzes.length - currentQuiz}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h6" align="center" gutterBottom>
            {video.title}
          </Typography>
          {video.mp4_file ? (
            <div ref={containerRef} style={{ position: 'relative' }}>
              {!isFullScreen && (
                <Box display="flex" justifyContent="center" marginBottom={2}>
                  <Button
                    onClick={handleFullScreenToggle}
                    variant="contained"
                    style={{ padding: '10px 20px' }}
                  >
                    Passer en Plein Écran
                  </Button>
                </Box>
              )}

              <video 
                ref={videoRef}
                controls 
                controlsList="nofullscreen"
                style={{ width: '100%' }}
                onPause={handleVideoPause}
                onEnded={handleVideoEnd}
                onTimeUpdate={handleTimeUpdate}
              >
                <source src={video.mp4_file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {showQuiz && currentQuiz < quizzes.length && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    zIndex: 1000,
                  }}
                >
                  <HelpOutlineIcon color="primary" style={{ fontSize: '3rem', marginBottom: '10px' }} />
                  <Typography variant="h5" gutterBottom>{quizzes[currentQuiz].question}</Typography>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {quizzes[currentQuiz].options.map((option, index) => (
                      <Button 
                        key={index} 
                        onClick={() => handleAnswer(index)}
                        variant="outlined" 
                        style={{ margin: '10px', minWidth: '200px' }}
                      >
                        {option}
                      </Button>
                    ))}
                  </Box>
                </div>
              )}
              {isQuizCompleted && (
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                  Quiz terminé! Réponses correctes: {correctAnswers} / {quizzes.length}
                </Typography>
              )}
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              La vidéo n'est pas encore disponible.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoListItem;
