import React from "react";
import { Box, Chip } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // basic Swiper styles
import "swiper/css/navigation"; // module styles for navigation
import { Navigation } from "swiper"; // import necessary modules for navigation

const SliderEvent = ({ keywords }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        position: "relative",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "40px",
        mt: 1,
      }}
    >
      <Swiper
        spaceBetween={1}
        slidesPerView={2}
        navigation={false}
        loop={keywords.length >= 3}
        autoplay={{
          delay: 200,
          disableOnInteraction: false,
        }}
      >
        {keywords.map((keyword, index) => (
          <SwiperSlide key={index}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Chip
                label={keyword}
                color="secondary"
                sx={{
                  backgroundColor: "#0D0729",
                  minWidth:"fit-content",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0D0729",
                  },
                  boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                  display: "flex", // Assurez-vous que le Chip lui-même utilise un display flex
                  alignItems: "center", // Centre verticalement le contenu du Chip
                }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default SliderEvent;
