import React, { useState } from "react";
import { Button, Modal, Box, TextField, Typography } from "@mui/material";
import { API_url } from "../GlobalVariables";
import { useAlert } from "../AlertContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ProblemFormModal = ({
  open,
  handleClose,
  startTime,
  endTime,
  expertEmail,
}) => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [id_user, setid_user] = useState(user.user._id);
  const [mail_user, setmail_user] = useState(user.user.mail);
  const [formValues, setFormValues] = useState({
    subject: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    console.log(formValues);
    handleClose();
    sendRequest(startTime, endTime, expertEmail);
  };

  function convertUtcToParisTime(utcDateString) {
    // Créer un objet Date à partir de la chaîne de date UTC
    const utcDate = new Date(utcDateString);

    // Utiliser Intl.DateTimeFormat pour formater la date dans le fuseau horaire de Paris
    // et le format désiré.
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Europe/Paris",
      hour12: false, // Utiliser le format 24h
    };
    const formatter = new Intl.DateTimeFormat("fr-FR", options);

    // Convertir et formater la date
    return formatter.format(utcDate);
  }
  

  function formatTimeWithTimezoneOffset(dateString) {
    const date = moment.tz(dateString, "YYYY-MM-DDTHH:mm:ssZ", "Europe/Paris");
    return date.format("YYYY-MM-DDTHH:mm:ss");
  }
  const sendRequest = async (startTime, endTime, expertEmail) => {
    const postData = {
      UserID: id_user,
      ExpertID: "65c0b2fb3b6d3ee3468ffc88",
      Subject: formValues.subject,
      Problem: formValues.description,
      MeetingPlatform: "Microsoft teams",
      Location: "Bureau",
      StartTime: formatTimeWithTimezoneOffset(startTime),
      EndTime: formatTimeWithTimezoneOffset(endTime),
      Status: "En attente",
      userEmail: mail_user,
      expertEmail: expertEmail,
    };

    const url = `${API_url || "http://localhost:5080/api"}/meeting/meeting`;
    axios
      .post(url, postData)
      .then(function (response) {
        console.log(response);
        if (response.status === 201) {
          showAlert("Événement ajouté avec succès", "success");
          setTimeout(() => {
            navigate(0);
          }, 1500);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Décrire un problème
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            id="subject"
            label="Sujet principal"
            name="subject"
            value={formValues.subject}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="description"
            label="Description du problème"
            name="description"
            multiline
            rows={4}
            value={formValues.description}
            onChange={handleInputChange}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button variant="contained" onClick={handleSubmit}>
              Valider
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ProblemFormModal;
