import React, { useState } from "react";
import { Card, CardContent, Typography, Dialog, DialogContent } from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

const AudioCard = ({ audio }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getLimitedDescription(description) {
    const maxLength = 70;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  console.log(audio.mp3_file);

  return (
    <>
      <div onClick={handleClickOpen} style={{ textDecoration: "none" }}>
        <Card
          style={{
            width: "80vw",
            maxWidth: "400px",
            height: "75vh",
            maxHeight: "600px",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            margin: "auto",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AudiotrackIcon style={{ fontSize: "5rem" }} color="primary" />
          </div>
          <CardContent
            style={{
              flexShrink: 0,
              textAlign: "center",
              padding: "16px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                fontSize: "1.2rem",
                marginBottom: "8px",
                minHeight: "50px",
              }}
            >
              {audio.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "0.875rem", minHeight: "40px" }}
            >
              {getLimitedDescription(audio.description)}
            </Typography>
          </CardContent>
        </Card>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent style={{ minWidth: "300px" }}>
          <Typography variant="h6">{audio.title}</Typography>
          {audio.mp3_file ? (
            <audio controls style={{ width: "100%" }}>
              <source src={audio.mp3_file} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Podcast pas encore disponible
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AudioCard;
