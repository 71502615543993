import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ListExpertsPage from "./pages/ListExpertsPage";
import ChatPage from "./pages/ChatPage";
import ChatConversation from "./pages/ChatConversation";
import LandingPage from "./pages/LandingPage";
import ModifTest from "./pages/ModifTest.js";
import Formations from "./pages/ListSeminaires";
import Fiches from "./pages/ListFiches.js";
import ProtectedRoute from "./pages/ProtectedRoute.js";
import DetailSeminaire from "./pages/DetailSeminaire";
import { AlertProvider } from "./AlertContext.js";
import { useDispatch } from "react-redux";
import { fetchFormations } from "./store/formationsReducer";
import Register from "./pages/Register.js";
import Login from "./pages/Login.js";
import { WebSocketProvider } from "./WebSocketContext";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFormations());
  }, [dispatch]);

  return (
    <AlertProvider>
      <Router>
        <WebSocketProvider>
          <div className="App">
            <div className="principal">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/test" element={<ModifTest />} />
                <Route path="/Accueil" element={<LandingPage />} />
                <Route path="/seminaire/:id" element={<DetailSeminaire />} />
                <Route path="/Formations" element={<Formations />} />
                <Route path="/Fiches" element={<Fiches />} />
                <Route path="/Register" element={<Register />} />
                <Route path="/Login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/Experts" element={<ListExpertsPage />} />
                  <Route path="/Chatbot" element={<ChatPage />}>
                    <Route path=":id" element={<ChatConversation />} />
                  </Route>
                </Route>

                <Route path="*" element={<LandingPage />} />
              </Routes>
            </div>
          </div>
        </WebSocketProvider>
      </Router>
    </AlertProvider>
  );
}

export default App;
