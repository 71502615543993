import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { Dialog, DialogContent, Button } from "@mui/material";

// Configuration du worker de pdfjs
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfPreviewModal = ({ pdfUrl, open, handleClose }) => {
  const canvasRef = useRef(null);
  const dialogContentRef = useRef(null); // Référence pour DialogContent
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const renderTaskRef = useRef(null);  // Stocker la tâche de rendu en cours
  const pdfRef = useRef(null);  // Stocker l'objet PDF pour éviter de le charger plusieurs fois

  useEffect(() => {
    const loadPdf = async () => {
      if (!open) return;

      setLoading(true);
      setError(null);

      try {
        // Charger le document PDF à partir de l'URL si non déjà chargé
        if (!pdfRef.current) {
          const loadingTask = pdfjsLib.getDocument(pdfUrl);
          const pdf = await loadingTask.promise;
          pdfRef.current = pdf; // Stocker le document PDF chargé
          setNumPages(pdf.numPages); // Nombre total de pages
        }

        // Afficher la première page ou la page actuelle
        renderPage(pageNumber, pdfRef.current);

        setLoading(false);
      } catch (err) {
        console.error("Erreur lors du chargement du PDF:", err);
        setError("Impossible de charger l'aperçu du PDF.");
        setLoading(false);
      }
    };

    if (pdfUrl && open) {
      loadPdf();
    }

    // Cleanup lors de la fermeture du modal
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [pdfUrl, open, pageNumber]);

  const renderPage = async (pageNum, pdf) => {
    // Annuler la tâche de rendu précédente si elle est en cours
    if (renderTaskRef.current) {
      renderTaskRef.current.cancel();
    }

    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1.5 });

    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas n'est pas encore monté");
      return;
    }

    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    // Démarrer une nouvelle tâche de rendu et la stocker
    renderTaskRef.current = page.render(renderContext);
    try {
      await renderTaskRef.current.promise;

      // Remonter automatiquement en haut du contenu de la modal
      if (dialogContentRef.current) {
        dialogContentRef.current.scrollTop = 0;
      }

    } catch (e) {
      if (e.name !== "RenderingCancelledException") {
        throw e;
      }
    } finally {
      renderTaskRef.current = null;  // Réinitialiser après le rendu
    }
  };

  const goToNextPage = async () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const goToPreviousPage = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    if (!open) {
      setPageNumber(1);
      setNumPages(null);
      pdfRef.current = null;  // Réinitialiser le PDF stocké lors de la fermeture
      if (canvasRef.current) {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent style={{ textAlign: "center", maxHeight: "80vh", overflowY: "auto" }} ref={dialogContentRef}>
        {loading && <p>Chargement de l'aperçu...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <canvas
          ref={canvasRef}
          style={{ maxWidth: "100%", border: "2px solid #ccc" }}
        />
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            onClick={goToPreviousPage}
            disabled={pageNumber <= 1}
          >
            Page précédente
          </Button>

          {/* Bouton Télécharger */}
          <a href={pdfUrl} download style={{ textDecoration: 'none' }}>
            <Button variant="contained">Télécharger</Button>
          </a>

          <Button
            variant="contained"
            onClick={goToNextPage}
            disabled={pageNumber >= numPages}
          >
            Page suivante
          </Button>
        </div>
        <p>
          Page {pageNumber} sur {numPages}
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default PdfPreviewModal;
