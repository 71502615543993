import Button from "./StyledBouton";
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SeminaireCard from "./SeminaireCard";
import defaultSeminaire from "./defaultSeminaire";

import { useSelector, useDispatch } from "react-redux";
import { fetchFormations } from "../store/formationsReducer";

const categories = [
  "IAG",
  "Prompt engineering",
  "Technologie",
  "ChatGPT",
  "Llama2",
  "OpenAI",
];

const description = {
  IAG: "Explorez les horizons de la création avec l'IA générative, où les machines donnent vie à des mondes imaginaires et inexplorés.",
  "Prompt engineering":
    "Sculptez les conversations et domptez les modèles de langage avec l'art de l'ingénierie des directives, où chaque mot compte et chaque requête est un défi créatif.",
  Technologie:
    "Plongez dans l'univers dynamique de la technologie, où les idées prennent vie et les frontières de l'impossible s'effacent devant la puissance de l'ingéniosité humaine.",
  ChatGPT:
    "Entrez dans un royaume où chaque phrase est une surprise, où les mots prennent vie et où les réponses captivent, grâce à la magie du modèle de langage ChatGPT.",
  Llama2:
    "Découvrez Llama2, le défi audacieux de Meta pour dominer le domaine des modèles de langage, prêt à rivaliser avec GPT pour le titre de maître de l'IA conversationnelle.",
  OpenAI:
    "Explorez les frontières de l'intelligence artificielle avec OpenAI, où l'innovation rencontre l'imagination pour repousser les limites de ce qui est possible dans un monde alimenté par la technologie.",
};

const Category = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(1),
  cursor: "pointer",
  fontWeight: "bolder",
  color: active ? `${theme.palette.secondary.main}` : "white",
  borderBottom: active ? `4px solid ${theme.palette.secondary.main}` : "none",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  marginRight: theme.spacing(2),
}));

const TrendingSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  const [trendingItems, settrendingItems] = useState(defaultSeminaire);

  const formations = useSelector((state) => state.formations);
  const lessThan1260 = useMediaQuery("(max-width:1260px)");

  useEffect(() => {
    // console.log("trending");
    // console.log(formations.formations);
    settrendingItems(formations.formations);
  }, [formations.formations]);
  const filteredTrendingItems = trendingItems.filter(
    (item) => item.category === activeCategory
  );

  return (
    <div
      style={{
        backgroundColor: "#0D0729",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      <Box sx={{ p: 2, width: "80%", margin: "auto" }}>
        <Typography variant="h5" sx={{ mb: 2, color: "white" }}>
          Les tendances du moment
        </Typography>
        <Box
          sx={{
            display: "flex",
            overflowX: "auto",
            p: 1,
            marginBottom: "20px",
          }}
        >
          {categories.map((category, index) => (
            <Category
              key={index}
              active={activeCategory === category}
              onClick={() => setActiveCategory(category)}
            >
              <Typography variant="subtitle1">{category}</Typography>
            </Category>
          ))}
        </Box>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{ mb: 2, color: "white" }}>
              {activeCategory}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 4, color: "white", textAlign: "justify" }}
            >
              <Typography
                variant="body1"
                sx={{ mb: 4, color: "white", textAlign: "justify" }}
              >
                {description[activeCategory]}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = "/Formations";
              }}
            >
              Formations
            </Button>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2} justifyContent="center">
              {filteredTrendingItems.map((item, index) => (
                <Grid item xs={12} sm={lessThan1260 ? 6 : 4} key={index}>
                  <SeminaireCard Seminaire={item} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TrendingSection;
// return (
//   <div
//     style={{
//       backgroundColor: "#0D0729",
//       paddingBottom: "30px",
//       paddingTop: "30px",
//     }}
//   >
//     <Box sx={{ p: 2, width: "80%", margin: "auto" }}>
//       <Typography variant="h5" sx={{ mb: 2, color: "white" }}>
//         Les tendances du moment
//       </Typography>
//       <Box
//         sx={{
//           display: "flex",
//           overflowX: "auto",
//           p: 1,
//           marginBottom: "20px",
//         }}
//       >
//         {categories.map((category, index) => (
//           <Category
//             key={index}
//             active={activeCategory === category}
//             onClick={() => setActiveCategory(category)}
//           >
//             <Typography variant="subtitle1">{category}</Typography>
//           </Category>
//         ))}
//       </Box>
//       <Grid container spacing={isMobile ? 2 : 4}>
//         <Grid item xs={12} md={3}>
//           <Typography variant="h5" sx={{ mb: 2, color: "white" }}>
//             {activeCategory}
//           </Typography>
//           <Typography
//             variant="body1"
//             sx={{ mb: 4, color: "white", textAlign: "justify" }}
//           >
//             {description[activeCategory]}
//           </Typography>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={() => {
//               window.location.href = "/Formations";
//             }}
//           >
//             Formations
//           </Button>
//         </Grid>
//         <Grid
//           container
//           xs={12}
//           md={9}
//           spacing={2}
//           justifyContent="center"
//           alignItems="flex-start"
//           sx={{ backgroundColor: "red" }}
//         >
//           {filteredTrendingItems.map((item, index) => (
//             // Ajustez ici les tailles pour xs, sm, et md pour correspondre à la distribution souhaitée
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <SeminaireCard Seminaire={item} />
//             </Grid>
//           ))}
//         </Grid>
//       </Grid>
//     </Box>
//   </div>
// );
