// MainPage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import ProblemForm from "../component/ProblemForm";
import ExpertCard from "../component/ExpertCard";
import { Box, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";
import { API_url } from "../GlobalVariables";
import { useAlert } from "../AlertContext";

const ListeExpert = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Gérez l'état de connexion ici
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [experts, setExperts] = useState([]);
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const { searchTerms, selectedSubdomains } = useSelector(
    (state) => state.filters
  );

  useEffect(() => {
    const fetchExperts = async () => {
      setIsLoading(true); // Commence le chargement
      setError(null); // Réinitialise les erreurs précédentes
      try {
        const res = await axios.get(
          `${API_url||"http://localhost:5080/api"}/meeting/getCalendarDisponibility`
        );
        if (res.status === 200) {
          // console.log(res.data)
          const Experts = res.data.map((obj, index) => {
            return {
              id: index + 1,
              photo:
                obj.user.photo ||
                "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsfGVufDB8fDB8fHww",
              nom: obj.user.name,
              mail: obj.user.mail,
              bio: obj.user.bio,
              phone: obj.user.phone,
              titre: obj.user.role + " " + obj.user.society,
              motsCles: obj.user.motsCles,
              disponibilites: obj.availability,
            };
          });
          setExperts(Experts);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des livres:", error);
        setError("Une erreur est survenue lors du chargement des experts.");
      } finally {
        setIsLoading(false); // Termine le chargement quelle que soit l'issue
      }
    };

    fetchExperts();
  }, [searchTerms, selectedSubdomains]);

  return (
    <Box>
      <Navbar isLoggedIn={isLoggedIn} />
      <Banner />
      <ProblemForm />
      {error && <p>{showAlert("Erreur lors de la récupération des données", "error")}</p>}{" "}
      {/* Affiche un message d'erreur si présent */}
      {isLoading ? (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ marginTop: "20px" }}
        >
          {Array.from(new Array(4)).map((_, index) => (
            <Grid item xs={12} sm={12} md={5} key={index}>
              <Skeleton
                variant="rectangular"
                width={310}
                height={210}
                animation="wave"
              />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ marginTop: "20px" }}
        >
          {experts
            .filter((expert) => {
              // console.log(searchTerms.matchesSociety);
              const matchesKeyword =
                !searchTerms.keyword ||
                expert.nom
                  .toLowerCase()
                  .includes(searchTerms.keyword.toLowerCase()) ||
                expert.titre
                  .toLowerCase()
                  .includes(searchTerms.keyword.toLowerCase());
              const matchesSociety =
                !searchTerms.society ||
                expert.titre
                  .toLowerCase()
                  .includes(searchTerms.society.toLowerCase());
              {
                /********* certains correspondent **********/
              }
              // const matchesSubdomains =
              //   !selectedSubdomains.length ||
              //   selectedSubdomains.some((subdomain) =>
              //     expert.motsCles.includes(subdomain)
              //   );
              {
                /********* tous correspondent **********/
              }
              const matchesSubdomains =
                selectedSubdomains.length === 0 ||
                selectedSubdomains.every((subdomain) =>
                  expert.motsCles.includes(subdomain)
                );

              return matchesKeyword && matchesSociety && matchesSubdomains;
            })
            .map((expert) => (
              <Grid item key={expert.id}>
                <ExpertCard expert={expert} />
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
};

export default ListeExpert;
