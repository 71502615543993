import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/userReducer";
import { resetFilters } from "../store/filtersReducer"; // <-- Import du reset des filtres

const NavbarContainer = styled(AppBar)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: "#0D0729",
  minHeight: "65px",
  boxShadow: "0px 0px 0px #C9C9C9",
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: "white",
}));

const NavbarButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(2),
  fontFamily: "'Poppins', sans-serif",
  fontWeight: "normal",
  "&:hover": {
    backgroundColor: "#112546",
    color: "white",
  },
}));

const Navbar = ({ isLoggedIn }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [Loged, setLoged] = useState(false);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Réinitialiser les filtres à chaque changement d'onglet
  const handleTabChange = (path) => {
    navigate(path);
    dispatch(resetFilters()); // Réinitialisation des filtres lors de chaque changement d'onglet
  };

  useEffect(() => {
    if (user.user) {
      setLoged(true);
    }
  }, [user]);

  return (
    <NavbarContainer position="static">
      <Toolbar disableGutters={isMobile}>
        <Box
          sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-start" }}
        >
          <img
            src="https://asset.brandfetch.io/idTqnAkJZ1/idI6_oi6lb.png"
            alt="Logo"
            style={{ maxHeight: "40px" }}
          />
        </Box>

        {/* Boutons centraux, visibles uniquement si non mobile */}
        {!isMobile && (
          <Box sx={{ display: "flex", flexGrow: 2, justifyContent: "center" }}>
            <NavbarButton onClick={() => handleTabChange("/Accueil")}>
              Accueil
            </NavbarButton>
            <NavbarButton onClick={() => handleTabChange("/Formations")}>
              Formations
            </NavbarButton>
            <NavbarButton onClick={() => handleTabChange("/Fiches")}>
              Fiches
            </NavbarButton>
            <NavbarButton onClick={() => handleTabChange("/Experts")}>
              Experts
            </NavbarButton>
            <NavbarButton onClick={() => handleTabChange("/Chatbot")}>
              Chatbot
            </NavbarButton>
          </Box>
        )}

        <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
          {isMobile ? (
            <div>
              <MenuButton color="inherit" aria-label="menu" onClick={handleMenu}>
                <MenuIcon />
              </MenuButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => { handleTabChange("/Accueil"); handleClose(); }}>
                  Accueil
                </MenuItem>
                <MenuItem onClick={() => { handleTabChange("/Formations"); handleClose(); }}>
                  Formations
                </MenuItem>
                <MenuItem onClick={() => { handleTabChange("/Fiches"); handleClose(); }}>
                  Fiches
                </MenuItem>
                <MenuItem onClick={() => { handleTabChange("/Experts"); handleClose(); }}>
                  Experts
                </MenuItem>
                <MenuItem onClick={() => { handleTabChange("/Chatbot"); handleClose(); }}>
                  Chatbot
                </MenuItem>
                {!Loged ? (
                  <MenuItem onClick={() => { navigate("/login"); handleClose(); }}>
                    Se connecter
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => { dispatch(logout()); navigate("/login"); handleClose(); }}>
                    Déconnexion
                  </MenuItem>
                )}
              </Menu>
            </div>
          ) : isLoggedIn ? (
            <MenuButton color="inherit">
              <AccountCircle />
            </MenuButton>
          ) : Loged ? (
            <NavbarButton color="inherit" onClick={() => { dispatch(logout()); navigate("/login"); }}>
              Déconnexion
            </NavbarButton>
          ) : (
            <NavbarButton color="inherit" onClick={() => { navigate("/login"); }}>
              Se connecter
            </NavbarButton>
          )}
        </Box>
      </Toolbar>
    </NavbarContainer>
  );
};

export default Navbar;
