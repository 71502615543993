import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  InputAdornment,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";
import staticPdfs from "../pages/staticPdfs.json"; // Import the static PDFs data

// Styles basés sur le FilterForm fourni
const SearchBarContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "auto",
  padding: theme.spacing(1),
  borderRadius: "20px",
  boxShadow: "none",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    color: "inherit",
    fontFamily: " 'Poppins', sans-serif",
    "& .MuiAutocomplete-input": {
      padding: "10px 6px",
      paddingLeft: theme.breakpoints.up("sm")
        ? `calc(0.15em + ${theme.spacing(4)})`
        : `calc(0.15em + ${theme.spacing(2)})`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  width: "70%",
  fontFamily: " 'Poppins', sans-serif",
  fontSize: "14px",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  [theme.breakpoints.up("sm")]: {
    width: "auto",
    minWidth: "15%",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "10px",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "20%",
    fontSize: "13px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
  },
}));

const PdfFilterForm = ({ onFilter }) => {
  const [localSearchTerms, setLocalSearchTerms] = useState({
    keyword: "",
    domaine: "",
  });
  const [domainOptions, setDomainOptions] = useState([]); // State to store unique domains

  // Extract unique domains from staticPdfs.json
  useEffect(() => {
    const uniqueDomains = [
      ...new Set(staticPdfs.map((pdf) => pdf.domaine).filter(Boolean)),
    ];
    setDomainOptions(uniqueDomains);
  }, []);

  const handleSearchChange = (field, newValue) => {
    setLocalSearchTerms((prevTerms) => ({
      ...prevTerms,
      [field]: newValue,
    }));
  };

  const handleSubmitSearch = () => {
    onFilter(localSearchTerms);
  };

  return (
    <Box sx={{ paddingTop: "0px" }}>
      <Box sx={{ backgroundColor: "#0D0729", padding: "20px" }}>
        <SearchBarContainer>
          {/* Barre de recherche par mot-clé (titre ou description) */}
          <StyledAutocomplete
            freeSolo
            options={[]} // Pas d'options prédéfinies, permet la recherche libre
            inputValue={localSearchTerms.keyword}
            onInputChange={(event, newInputValue) => {
              handleSearchChange("keyword", newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Rechercher par titre ou description"
                variant="outlined"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    handleSubmitSearch();
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {/* Recherche par domaine */}
          <StyledAutocomplete
            options={domainOptions} // Use the dynamic domain options
            value={localSearchTerms.domaine}
            onChange={(event, newValue) =>
              handleSearchChange("domaine", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Sélectionner un domaine"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {/* Bouton de recherche */}
          <SearchButton onClick={handleSubmitSearch}>Rechercher</SearchButton>
        </SearchBarContainer>
      </Box>
    </Box>
  );
};

export default PdfFilterForm;
