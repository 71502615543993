import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchTerms,
  setSelectedSubdomains,
  removeSelectedSubdomain,
  addSelectedSubdomain,
} from "../store/filtersReducer";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  Chip,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import domaines from "./defaultDomaines"; // Assurez-vous que le chemin d'accès est correct

const SearchBarContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%", // Utilisez 100% de la largeur pour les très petits écrans
  margin: "auto",
  padding: theme.spacing(1),
  borderRadius: "20px",
  boxShadow: "none",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row", // Disposition en ligne pour les écrans moyens et plus grands
    width: "80%", // Ajustez selon le besoin
  },
  [theme.breakpoints.up("md")]: {
    width: "60%", // Ajustez selon le besoin pour les grands écrans
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "100%", // Assurez-vous qu'il occupe toute la largeur du conteneur parent
  "& .MuiInputBase-root": {
    color: "inherit",
    fontFamily: " 'Poppins', sans-serif",
    "& .MuiAutocomplete-input": {
      padding: "10px 6px",
      // Ajustement conditionnel du paddingLeft en fonction de la taille de l'écran
      paddingLeft: theme.breakpoints.up("sm")
        ? `calc(0.15em + ${theme.spacing(4)})`
        : `calc(0.15em + ${theme.spacing(2)})`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent", // Supprime la bordure pour un look épuré
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Supprime la bordure du champ
      width: "100%", // Assurez-vous que le champ occupe toute la largeur
    },
    "&:hover fieldset": {
      border: "none", // Garde la bordure invisible au survol
    },
    "&.Mui-focused fieldset": {
      border: "none", // Garde la bordure invisible lors de la sélection
    },
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none", // Supprime la ligne du bas par défaut
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none", // Garde la ligne du bas invisible au survol
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none", // Garde la ligne du bas invisible après sélection
  },
  // Supprimez la déclaration en double de marginBottom pour éviter la confusion et l'inefficacité
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(0), // Ajoute un espacement entre les éléments sur les petits écrans
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 4), // Augmente le padding horizontal pour élargir le bouton
  width: "70%", // Utilise toute la largeur sur petits écrans pour une meilleure accessibilité
  fontFamily: " 'Poppins', sans-serif",
  fontSize: "14px",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  [theme.breakpoints.up("sm")]: {
    width: "auto", // Permet au bouton d'ajuster sa taille automatiquement en fonction du contenu
    minWidth: "20%", // Assurez-vous que le bouton a une taille minimale sur les écrans moyens et grands
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "15px",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "25%", // Augmente la taille minimale sur les écrans plus grands pour une meilleure visibilité
    fontSize: "13px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
  },
}));

const FiltersContainer = styled(Box)(({ theme }) => ({
  margin: "auto",
  width: "90%", // Increase the width on smaller screens for better visibility
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginTop: "30px",
  [theme.breakpoints.up("md")]: {
    width: "80%", // Adjust for larger screens
  },
}));

const DomainChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontFamily: " 'Poppins', sans-serif",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem", // Smaller chips for mobile
  },
}));

let specialistOptions = [
  "Directeur Général",
  "Directeur Data",
  "Directeur Conseil",
  "engineer",
  "Consultant",
];
const societyOptions = ["Acadys", "BFTI"];

const ProblemForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [selectedDomain, setSelectedDomain] = useState("");

  const { searchTerms, selectedSubdomains } = useSelector(
    (state) => state.filters
  );

  const [localSearchTerms, setLocalSearchTerms] = useState({
    keyword: "",
    society: "",
    expert: "",
  });

  const handleLocalSearchTermsChange = (field, newValue) => {
    setLocalSearchTerms((prevTerms) => ({
      ...prevTerms,
      [field]: newValue,
    }));
  };

  const handleSubmitSearch = () => {
    dispatch(setSearchTerms(localSearchTerms));
  };

  const handleSubdomainClick = (sousDomaine) => {
    if (selectedSubdomains.includes(sousDomaine)) {
      dispatch(removeSelectedSubdomain(sousDomaine));
    } else {
      dispatch(addSelectedSubdomain(sousDomaine));
    }
  };

  return (
    <Box sx={{ paddingTop: "0px" }}>
      <Box sx={{ backgroundColor: "#0D0729", padding: "20px" }}>
        <SearchBarContainer>
          <StyledAutocomplete
            freeSolo
            options={specialistOptions.slice(0, 5)} // Limite les options à 5
            inputValue={localSearchTerms.keyword}
            onInputChange={(event, newInputValue) => {
              handleLocalSearchTermsChange("keyword", newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Sujet a chercher "
                variant="outlined"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    handleSubmitSearch();
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <StyledAutocomplete
            freeSolo
            options={societyOptions.slice(0, 5)} // Limite les options à 5
            inputValue={localSearchTerms.society}
            onInputChange={(event, newInputValue) => {
              handleLocalSearchTermsChange("society", newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Entreprise"
                variant="outlined"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    handleSubmitSearch();
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <SearchButton
            type="submit"
            // endIcon={<ArrowForwardIosIcon />}
            onClick={handleSubmitSearch}
          >
            Rechercher
          </SearchButton>
        </SearchBarContainer>
      </Box>
      <FiltersContainer>
        <Box>
          <Typography
            variant="h6"
            sx={{ fontFamily: " 'Poppins', sans-serif" }}
          >
            Domaines
          </Typography>
          <Stack direction="row" flexWrap="wrap">
            {Object.keys(domaines).map((domaine) => (
              <DomainChip
                key={domaine}
                label={domaine}
                onClick={() => {
                  if (selectedDomain === "" || selectedDomain !== domaine) {
                    setSelectedDomain(domaine);
                  } else {
                    setSelectedDomain("");
                  }
                }}
                color={selectedDomain === domaine ? "secondary" : "default"}
              />
            ))}
          </Stack>
        </Box>
        {selectedDomain && (
          <Box>
            <Typography
              variant="h6"
              sx={{ fontFamily: " 'Poppins', sans-serif" }}
            >
              Sous-domaines de {selectedDomain}
            </Typography>
            <Stack direction="row" flexWrap="wrap">
              {domaines[selectedDomain].map((sousDomaine) => (
                <DomainChip
                  key={sousDomaine}
                  label={sousDomaine}
                  onClick={() => handleSubdomainClick(sousDomaine)}
                  variant="outlined"
                  color={
                    selectedSubdomains.includes(sousDomaine)
                      ? "secondary"
                      : "default"
                  }
                />
              ))}
            </Stack>
          </Box>
        )}
      </FiltersContainer>
    </Box>
  );
};

export default ProblemForm;
