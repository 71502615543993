import React, { useState } from "react";
import Button from "../component/StyledBouton";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { API_url } from "../GlobalVariables";

const LandingPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formationId, setFormationId] = useState("65d70da5a925cc53cf32c0cc");

  //
  const uploadDocument = async (file, formationId) => {
    const formData = new FormData();
    console.log(file);
    formData.append("document", file);

    try {
      const response = await axios.post(
        `${API_url}/formation/uploadDocument/${formationId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("ok");
      console.log(response.data); // Ou gérer la réponse comme nécessaire
    } catch (error) {
      console.error("Erreur lors de l'upload du document:", error);
      // Gérer l'erreur comme nécessaire
    }
  };

  // Fonction appelée lorsque l'utilisateur sélectionne un fichier
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Fonction appelée lorsque l'utilisateur clique sur le bouton pour uploader le document
  const handleUploadClick = () => {
    if (selectedFile && formationId) {
      uploadDocument(selectedFile, formationId);
    } else {
      console.log("Aucun fichier sélectionné ou ID de formation manquant");
      // Afficher une alerte à l'utilisateur ou gérer l'absence de fichier/ID de formation
    }
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        accept=".pdf,.doc,.docx" // Ajustez selon les types de fichiers acceptés
        style={{ display: "block", margin: "20px 0" }}
      />
      <Button
        variant="contained"
        component="span"
        onClick={handleUploadClick}
        startIcon={<CloudUploadIcon />}
      >
        Télécharger Document
      </Button>
    </div>
  );
};

export default LandingPage;
