import { Button } from "@mui/material"; // Corrected the import statement
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(2),
  fontFamily: "'Poppins', sans-serif", // Ensure the font is imported in your project
  fontWeight: "normal",
  backgroundColor:theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: "#112546",
    color: "white",
  },
}));

export default StyledButton;
