// AlertContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const AlertContext = createContext();

const AlertComponent = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertProvider = ({ children }) => {
  const [state, setState] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const showAlert = useCallback((message, severity = 'success') => {
    setState({ open: true, message, severity });
  }, []);

  const closeAlert = useCallback(() => {
    setState((prevState) => ({ ...prevState, open: false }));
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      <Snackbar open={state.open} autoHideDuration={6000} onClose={closeAlert}>
        <AlertComponent onClose={closeAlert} severity={state.severity}>
          {state.message}
        </AlertComponent>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
