import React, { useState } from "react";
import { ListItem, ListItemText, ListItemAvatar, Avatar, Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import { useTheme } from '@mui/material/styles';

const AudioListItem = ({ audio }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getLimitedDescription(description) {
    const maxLength = isSmallScreen ? 80 : 140;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  return (
    <>
      <ListItem
        button
        onClick={handleClickOpen}
        divider
        style={{ minHeight: "70px" }}
      >
        <ListItemAvatar>
          <Avatar>
            <AudiotrackIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={audio.title}
          secondary={getLimitedDescription(audio.description)}
        />
      </ListItem>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="h6">{audio.title}</Typography>
          {audio.mp3_file ? (
            <audio controls style={{ width: "100%" }}>
              <source src={audio.mp3_file} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Podcast pas encore disponible
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AudioListItem;
