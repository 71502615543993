import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const BannerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "260px", 
  backgroundColor:  "#0D0729",
  backgroundSize: "cover", 
  backgroundPosition: "center", 
  backgroundRepeat: "no-repeat", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center", 
  color: theme.palette.primary.contrastText,
  transition: "background-image 0.5s ease-in-out",
  [theme.breakpoints.down("md")]: {
    height: "150px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "100px",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  fontSize: "10rem", // Taille de police extrêmement grande
  [theme.breakpoints.down("sm")]: {
    fontSize: "5rem", // Taille réduite pour les petits écrans
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "10rem", // Taille très grande pour les écrans moyens et plus grands
  },
}));

const Banner = () => {
  const [imageLoaded, setImageLoaded] = useState(false); 

  useEffect(() => {
    const bannerImg = new Image();
    bannerImg.src = "/Banner.png"; 
    bannerImg.onload = () => {
      document.querySelector(".banner-container").style.backgroundImage = "url('/Banner.png')";
      setImageLoaded(true);
    };
  }, []);

  return (
    <BannerContainer className="banner-container">
      {!imageLoaded && ( 
        <ResponsiveTypography variant="h1">
          Acadys
        </ResponsiveTypography>
      )}
    </BannerContainer>
  );
};

export default Banner;
