import React from "react";
import {
  Card,
  Typography,
  Box,
  Stack,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/material/styles";

const EventCardWrapper = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "hidden",
  borderRadius: "50px",
  marginTop: "20px",
  marginBottom: "auto",
  border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const ImageArea = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight:"400px",
  height: "100%", // Prendre toute la hauteur de la Card
  overflow: "hidden", // Masquer les débordements
  display: "flex",
  justifyContent: "center", // Centrer l'image si elle est plus large que le conteneur
  alignItems: "center", // Centrer l'image verticalement
  [theme.breakpoints.up("md")]: {
    width: "50%",
    height: "100%",
  },
}));

const ContentArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  
  [theme.breakpoints.up("md")]: {
    width: "50%",
    
  },
}));

const EventCard = ({ event }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Utilisez des unités relatives pour la taille de la police
  const textStyle = {
    fontSize: "calc(0.6rem + 0.2vw)", // Ajustez en fonction de la taille de la viewport
  };

  return (
    <EventCardWrapper
      sx={{
        width: isMobile ? "95%" : "80%",
        maxWidth: "1400px",
        height: "fit-content",
      }}
    >
      <ImageArea component="img" src={event.images[0]} alt={event.title} />
      <ContentArea>
        <Box
          sx={{
            margin: "auto",
            padding:"40px",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" sx={textStyle}>
            {event.category}
          </Typography>
          <Typography variant="h6" sx={{ my: 1, ...textStyle }}>
            {event.title}
          </Typography>
          <Box sx={{ maxHeight:"290px",overflow: "hidden" }}>
            <Typography
              sx={textStyle}
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: event.eventDescription }}
            ></Typography>
          </Box>
          <Typography sx={{ mb: 1, ...textStyle }}>par {event.host}</Typography>
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            <EventIcon />
            {event.date.slice(0, 1).map((d, index) => (
              <Typography key={index} variant="body2" sx={textStyle}>
                {d.startDate} - {d.endDate}
              </Typography>
            ))}
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            {event.formers.map((former, index) => (
              <Chip
                sx={{ fontSize: "calc(0.6rem + 0.2vw)", m: 0.5, ...textStyle }} // Ajustement dynamique de la taille de la police pour les Chips
                key={index}
                avatar={<PersonIcon />}
                label={former.name}
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      </ContentArea>
    </EventCardWrapper>
  );
};

export default EventCard;
