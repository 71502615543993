// Dans ChatConversation.js
import React, { useState, useEffect, useRef, useReducer } from "react";
import io from "socket.io-client";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  IconButton,
  Popover,
  Rating,
  TextareaAutosize,
  Modal,
  Avatar,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { parseISO, format, subHours } from "date-fns";
import { fr } from "date-fns/locale";
import { API_url } from "../GlobalVariables";
import { CHAT_url } from "../GlobalVariables";

import { useAlert } from "../AlertContext";
import { useSelector } from "react-redux";
import { useWebSocket } from "../WebSocketContext";

/***************** styled component *************************************************/

const CustomTextField = styled(TextField)(({ theme }) => ({
  mr: 1,
  borderRadius: 2,
  backgroundColor: "transparent",
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiInputBase-root": {
    border: "none",
    "&::placeholder": {
      color: "text.disabled",
      opacity: 1,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const CustomTextFieldComment = styled(TextField)(({ theme }) => ({
  mr: 1,
  backgroundColor: "transparent",
  "& .MuiInputBase-input": {
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
  "& .MuiInputBase-root": {
    border: "none",
    "&::placeholder": {
      color: "black",
      opacity: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  overflow: "auto",

  margin: "auto",
  "&::-webkit-scrollbar": {
    width: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "5px",
    },
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "darkgrey",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "grey",
  },
  bgcolor: "#171717",
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  margin: "auto",
  marginTop: theme.spacing(2),
  border: "1px solid #ccc",
  borderRadius: "20px",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },
}));

// Définition des types d'action
const actionTypes = {
  SET_MESSAGES: "SET_MESSAGES",
  ADD_MESSAGE: "ADD_MESSAGE",
  UPDATE_LAST_BOT_MESSAGE: "UPDATE_LAST_BOT_MESSAGE",
  RESET_MESSAGES: "RESET_MESSAGES",
};

function messagesReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_MESSAGES:
      return action.payload;
    case actionTypes.ADD_MESSAGE:
      return [...state, action.payload];
    case actionTypes.UPDATE_LAST_BOT_MESSAGE:
      if (state.length > 0 && state[state.length - 1].sender === "bot") {
        // Met à jour le dernier message du bot
        return state.map((msg, index) =>
          index === state.length - 1
            ? {
                ...msg,
                text: msg.text + action.payload.replace(/\\n/g, "<br />"),
              }
            : msg
        );
      } else {
        return [
          ...state,
          {
            sender: "bot",
            text: action.payload.replace(/\\n/g, ""),
          },
        ];
      }
    case actionTypes.RESET_MESSAGES:
      return [];
    default:
      return state;
  }
}

/************************* core component *****************************************/
const expert_fixed = {
  _id: "65c390407c2f380843eec663",
  name: "Expert acadys",
  society: "Acadys",
  role: "Expert acadys",
  phone: "0606060606",
  mail: "georges.bressange@acadys.com",
  location: "France",
  type: "expert",
  website: "Acadys.com",
  bio: "Ingénieur en informatique et Docteur en sciences de gestion.",
  adresse_facturation: "7 cité du midi 75018",
  photo:
    "https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2281862025.jpg",
  motsCles: [
    "Chatbots pour le service client",
    "Prototypage rapide",
    "Analyse prédictive",
    "Conception assistée par IA",
    "R&D accélérée",
  ],
  Conversations: [],
  createdAt: "2024-02-07T14:14:24.488Z",
  updatedAt: "2024-02-08T15:47:21.720Z",
  __v: 0,
  nb_conv: 0,
  creneauDisponible: {
    start: "2024-09-05T09:00:00.000Z",
    end: "2024-09-05T10:00:00.000Z",
  },
};
const ChatConversation = () => {
  /************************* React Hooks *****************************************/
  const { id } = useParams();
  const messagesEndRef = useRef(null);
  const [messages, dispatch] = useReducer(messagesReducer, []);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [conversationId, setConversationId] = useState(useParams().id);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editable, setEditable] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rating, setRating] = useState(0);
  const [expert, setExpert] = useState(expert_fixed);
  const { showAlert } = useAlert();
  const tokenCounter = useRef(0);

  const user = useSelector((state) => state.user);
  const [id_user, setid_user] = useState(user.user._id);
  // const socketUrl = "http://37.187.39.75:92";

  const socket = useWebSocket({
    transports: ['websocket'] // Force l'utilisation de WebSocket
  });
  
  /********* */
  useEffect(() => {
    const tokenThreshold = 0;
    if (socket.current) {
      socket.current.on("chat_token", (msg) => {
        console.log("Token recu : " + msg.token);
        console.log(`Traitement du token  : ${msg.token}`);
          dispatch({
            type: actionTypes.UPDATE_LAST_BOT_MESSAGE,
            payload: msg.token,
          });
      });

      socket.current.on("chat_data", (msg) => {
        console.log("*****");
        console.log("chat_data");
        console.log(msg.message);
        setIsLoading(false);
        if (msg.event != "add_chat") {
          navigate(0);
        }
      });

      socket.current.on("event", (msg) => {
        if (msg.message === "stop") {
          setIsLoading(false);
        }

        if (msg.message === "wait") {
          socket.current.emit("listener", { message: "wait" });
        }
        console.log(msg);
      });
    }
    return () => {
      socket.current.off("connect");
      socket.current.off("disconnect");
      socket.current.off("connect_error");
      socket.current.off("reconnect_failed");
      socket.current.off("chat_token");
      socket.current.off("chat_data");
      socket.current.off("event");
    };
  }, [socket.current]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  /************************* index chat bot variables *****************************************/
  const open = Boolean(anchorEl);
  const id_ = open ? "simple-popover" : undefined;

  const lastBotMessageIndex = messages
    .slice()
    .reverse()
    .findIndex((msg) => msg.sender === "bot");
  const lastIndexToUse = messages.length - 1 - lastBotMessageIndex;

  /************************* use Effects *****************************************/

  useEffect(() => {
    if (id === "0") {
      setConversationId(null);
      dispatch({ type: actionTypes.RESET_MESSAGES });
      setEditable(true);
    }

    const fetchChats = async () => {
      // console.log("id params : " + id);
      if (conversationId !== id) {
        setConversationId(id);
      }

      try {
        const url = `${CHAT_url}/chat?id_user=${id_user}&id_conv=${id}`;
        // console.log("j'envois : " + url.slice(24, url.length));
        const response = await axios.get(url);
        if (response.status === 200 && response.data) {
          const data = response.data;
          // console.log(data);
          if (data.notation !== 0) {
            setEditable(false);
            setRating(data.notation);
            setCurrentComment(data.comment);
          } else {
            setEditable(true);
            setRating(data.notation);
            setCurrentComment(data.comment);
          }
          const firstExchanges = data.Exchange;
          const newMessages = Object.keys(firstExchanges).reduce((acc, key) => {
            let startIndex = 0;

            if (firstExchanges[key][0].trim() === "") {
              setCurrentMessage(firstExchanges[key][1]);
              startIndex = 2;
            }

            const messagesForConv = firstExchanges[key]
              .slice(startIndex)
              .map((message, index) => {
                const msgi = message.replace(/\\n/g, "<br />");
                const newMessage = {
                  text: msgi,
                  sender: (startIndex + index) % 2 === 0 ? "bot" : "user",
                };
                return newMessage;
              });
            return [...acc, ...messagesForConv];
          }, []);
          dispatch({
            type: actionTypes.SET_MESSAGES,
            payload: newMessages.reverse(),
          });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des chats:", error);
      }
    };

    // console.log("l'id qu'on a  :  " + id);
    if (id !== "0") {
      // console.log("on fetch avec id = " + id);
      fetchChats();
    }

    if (id === "0" && conversationId) {
      setConversationId(null);
      dispatch({ type: actionTypes.RESET_MESSAGES });
    }
  }, [id]);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  useEffect(() => {
    if (user.user) {
      setid_user(user.user._id);
    }
  }, [user.user]);
  // function to send promp or question to the model
  const sendMessage = (event) => {
    event.preventDefault();
    if (currentMessage.trim() === "") return;
    setIsLoading(true);
    const formattedMessage = currentMessage.replace(/\n/g, "<br />");
    dispatch({
      type: actionTypes.ADD_MESSAGE,
      payload: { text: formattedMessage, sender: "user" },
    });
    setCurrentMessage("");
    const jsonToSend = {
      id_user: id_user,
      question: currentMessage,
    };
    console.log("conversationId  :  " + conversationId);
    if (
      (!conversationId || conversationId === null) &&
      conversationId !== "0"
    ) {
      console.log("create_chat");
      socket.current.emit("create_chat", jsonToSend);
    } else {
      console.log("add_chat");
      jsonToSend.id_conv = conversationId;
      socket.current.emit("add_chat", jsonToSend);
    }
  };
  const fetchExperts = async () => {
    try {
      const res = await axios.get(
        `${API_url || "http://localhost:5080/api"}/meeting/getFreeExpert`
      );
      if (res.status === 200) {
        console.log(res.data);
        const Expert = {
          _id: res.data.expert._id,
          name: res.data.expert.name,
          society: res.data.expert.society,
          role: res.data.expert.role,
          phone: res.data.expert.phone,
          mail: res.data.expert.mail,
          location: res.data.expert.location,
          type: res.data.expert.type,
          website: res.data.expert.website,
          bio: res.data.expert.bio,
          adresse_facturation: res.data.expert.adresse_facturation,
          photo: res.data.expert.photo,
          motsCles: res.data.expert.motsCles,
          Conversations: res.data.expert.Conversations,
          createdAt: res.data.expert.createdAt,
          updatedAt: res.data.expert.updatedAt,
          __v: res.data.expert.__v,
          nb_conv: res.data.expert.nb_conv,
          creneauDisponible: res.data.availability[1],
        };
        setExpert(Expert);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des livres:", error);
      showAlert("Erreur lors de la récupération des livres", "error");
    } finally {
      setIsLoading(false); // Termine le chargement quelle que soit l'issue
    }
  };
  const handleSendClick = () => {
    if (rating <= "3") {
      if (currentComment != "") {
        fetchExperts();
        handleOpenModal();
      } else {
        showAlert("Precisez la raison de votre non satisfaction !", "warning");
      }
    } else {
      showAlert("Heureux de savoir que vous etes satisfait !", "success");
      addNoteToConversation();
      setTimeout(() => {
        navigate(0);
      }, 3000);
    }
  };
  // function to handle the rating
  const handleRatingClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // alert("copié §");
      showAlert("copié !", "info");
    });
  };
  //model part
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  async function addNoteToConversation() {
    const url = `${CHAT_url}/chat/notation?id_user=${id_user}&id_conv=${id}`;
    const data = { notation: rating, comment: currentComment };

    try {
      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(
        "Note et commentaire ajoutés avec succès à la conversation :",
        response.data
      );
      showAlert(
        "Note et commentaire ajoutés avec succès à la conversation",
        "success"
      );
    } catch (error) {
      showAlert("Erreur lors de l'ajout de la note", "error");
      console.error(
        "Erreur lors de l'ajout de la note :",
        error.response ? error.response.data : error.message
      );
    }
  }
  function formaterPlageDisponibilite(creneau) {
    const dateDebut = parseISO(creneau.start);
    const dateFin = parseISO(creneau.end);

    // Soustraire une heure aux dates de début et de fin
    const dateDebutAjustee = subHours(dateDebut, 0);
    const dateFinAjustee = subHours(dateFin, 0);

    const formattedDate = format(dateDebutAjustee, "eeee d MMMM yyyy", {
      locale: fr,
    });
    const heureDebut = format(dateDebutAjustee, "HH:mm", { locale: fr });
    const heureFin = format(dateFinAjustee, "HH:mm", { locale: fr });

    return `Disponible le ${formattedDate} de ${heureDebut} à ${heureFin}`;
  }
  const sendRequest = async (startTime, endTime, expertEmail) => {
    const currentCommentFormatted = `<strong>${currentComment}</strong>`;

    let lastMessageFormatted = "";
    let secondLastMessageFormatted = "";
    if (messages.length >= 2) {
      const lastMessage = messages[messages.length - 1];
      const secondLastMessage = messages[messages.length - 2];

      lastMessageFormatted = `bot : ${lastMessage.text}`;
      secondLastMessageFormatted = `message : ${secondLastMessage.text}`;
    } else if (messages.length === 1) {
      const lastMessage = messages[0];
      lastMessageFormatted = `bot : ${lastMessage.text}`;
    }

    const problemDescription = `
  <p>${currentCommentFormatted}</p>
  <p>${secondLastMessageFormatted}</p>
  <p>${lastMessageFormatted}</p>
`;
    const postData = {
      UserID: user.user._id,
      ExpertID: expert._id,
      Subject: "Expertise Acadoc",
      Problem: problemDescription,
      MeetingPlatform: "Microsoft teams",
      Location: "Bureau",
      StartTime: expert.creneauDisponible.start,
      EndTime: expert.creneauDisponible.end,
      Status: "En attente",
      userEmail: user.user.mail,
      expertEmail: expert.mail,
    };
    console.log(postData);
    const url = `${API_url || "http://localhost:5080/api"}/meeting/meeting`;
    console.log(url);
    axios
      .post(url, postData)
      .then(function (response) {
        console.log(response);
        if (response.status === 201) {
          addNoteToConversation();
          showAlert("Evenement ajouté avec succée !", "success");
          setTimeout(() => {
            navigate(0);
          }, 3000);
        }
      })
      .catch(function (error) {
        showAlert("Erreur lors de l'ajout de l'evenement !", "error");
        console.log(error);
      });
  };
  const handleCreneauConfirm = (creneauChoisi) => {
    console.log("Envoi de la requête POST avec le créneau :", creneauChoisi);

    sendRequest();
    handleCloseModal();
    handleClose();
  };

  return (
    <Grid container sx={{ p: 1, bgcolor: "#171717" }}>
      <ChatContainer
        sx={{
          height: { xs: "75vh", sm: "70vh", md: "70vh", lg: "70vh" },
          maxHeight: "75vh",
          width: { xs: "95%", sm: "85%", md: "80%" },
        }}
      >
        <List sx={{ width: "100%", maxHeight: "65vh" }}>
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: msg.sender === "user" ? "flex-end" : "flex-start",
                my: 0.5,
              }}
            >
              <Box
                sx={{
                  maxWidth: { xs: "100%", sm: "70%" },
                  fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px" },
                  color: "white",
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: "#383838",
                  p: 1,
                  textAlign: "left",
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: msg.text }}></span>
              </Box>

              {msg.sender === "bot" && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                  {/* Icône de copie pour chaque message du bot */}
                  <IconButton onClick={() => handleCopy(msg.text)}>
                    <ContentCopyIcon sx={{ color: "white" }} />
                  </IconButton>

                  {/* Afficher ces icônes seulement pour le dernier message du bot */}
                  {index === lastIndexToUse && (
                    <>
                      <IconButton onClick={handleRatingClick}>
                        {editable ? (
                          <StarBorderIcon sx={{ color: "white" }} />
                        ) : (
                          <StarIcon sx={{ color: "yellow" }} />
                        )}
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          window.location.href = "/experts";
                        }}
                      >
                        <AccountCircleIcon sx={{ color: "white" }} />
                      </IconButton>
                    </>
                  )}
                </Box>
              )}
            </ListItem>
          ))}
          <Popover
            id={id_}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                width: { xs: "90%", sm: "600px", lg: "800px" },
              },
            }}
          >
            <Box sx={{ p: 2, backgroundColor: "white" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                {editable && (
                  <IconButton
                    onClick={() => {
                      handleSendClick();
                    }}
                    sx={{ ml: "auto" }}
                  >
                    <SendIcon sx={{ color: "black" }} />
                  </IconButton>
                )}
              </Box>
              <CustomTextFieldComment
                fullWidth
                autoComplete="off"
                value={currentComment}
                onChange={(e) => setCurrentComment(e.target.value)}
                placeholder="Vous pouvez laisser un commentaire pour l'expert..."
                variant="outlined"
                multiline
                rows={1}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    handleSendClick();
                  }
                }}
              />
            </Box>
          </Popover>

          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                width: { xs: "90%", sm: 390 }, // Responsive width
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={expert.name}
                src={expert.photo}
                sx={{ width: 56, height: 56, mb: 2 }}
              />
              <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
                {expert.name}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {expert.role}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: 2, maxWidth: "fit-content" }}
              >
                {formaterPlageDisponibilite(expert.creneauDisponible)}
              </Typography>
              {/* Conteneur pour les boutons */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" }, // Stack buttons vertically on small screens, horizontally on larger
                  gap: 2, // Space between buttons
                  width: "100%", // Ensure container takes full width to center buttons correctly
                  justifyContent: "center", // Center buttons horizontally
                }}
              >
                <Button
                  onClick={() => handleCreneauConfirm(expert.creneauDisponible)}
                  variant="contained"
                >
                  Confirmer le créneau
                </Button>
                <Button
                  onClick={() => {
                    addNoteToConversation();
                    window.location.href = "/experts";
                  }}
                  variant="outlined"
                >
                  Demander un autre créneau
                </Button>
              </Box>
            </Box>
          </Modal>

          {isLoading && (
            <Box
              sx={{
                height: "fit-content",
                paddingTop: "20px",
                width: "50%",
                margin: "auto",
                color: "grey.500",
              }}
            >
              <LinearProgress color="inherit" sx={{ borderRadius: "50px" }} />
            </Box>
          )}
          <div ref={messagesEndRef} />
        </List>
      </ChatContainer>
      {editable && (
        <InputContainer>
          <CustomTextField
            fullWidth
            autoComplete="off"
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            placeholder="Question ..."
            variant="outlined"
            multiline
            rows={1}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                sendMessage(e);
              }
            }}
          />
          <Button
            variant="contained"
            onClick={sendMessage}
            sx={{
              p: "10px",
              bgcolor: "transparent",
              boxShadow: "none",
              "&:hover": {
                bgcolor: "transparent",
                boxShadow: "none",
              },
              "& .MuiButton-startIcon": {
                color: "#fff",
              },
            }}
            startIcon={
              isLoading ? (
                <StopIcon
                  onClick={() => {
                    // console.log("emit");
                    socket.current.emit("listener", { message: "stop" });
                    showAlert("La generation a été stopé", "warning");
                    // console.log("stop");
                  }}
                />
              ) : (
                <SendIcon />
              )
            }
          />
        </InputContainer>
      )}
    </Grid>
  );
};

export default ChatConversation;
