import React, { useState } from "react";
import { format, parseISO, add, isWeekend, isToday, isAfter } from "date-fns";

import { fr } from "date-fns/locale";
import { Box, Typography, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import FormModal from "./FormModal";

const CalendarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#f0f0f0",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  userSelect: "none",
}));

const DayColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%", // Adaptation pour les petits écrans
  },
}));

const TimeSlotButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6rem", // Réduction de la taille de la police pour les petits écrans
  },
  fontFamily: " 'Poppins', sans-serif",
}));

const Calendar = ({ creneaux, expertEmail }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCre, setselectedCre] = useState({ start: "", end: "" });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // if (expertEmail === "mohamed.sadadou@acadys.com") {
  //   console.log(creneaux);
  // }
  const today = new Date();
  const days = [];
  let day = today;

  while (days.length < 5) {
    if (!isWeekend(day)) {
      days.push(day);
    }
    day = add(day, { days: 1 });
  }
  const isUpcomingTimeSlot = (creneau) => {
    const now = new Date();
    const creneauDate = parseISO(creneau.start);

    return isToday(creneauDate) && isAfter(creneauDate, now);
  };

  // console.log("************")
  // console.log(expertEmail)
  const creneauxParJour = days.map((day) => {
    return creneaux.filter((creneau) => {
      const creneauDate = parseISO(creneau.start);
      creneauDate.setHours(creneauDate.getHours());
      return (
        format(creneauDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") &&
        creneauDate.getHours() >= 9 &&
        creneauDate.getHours() < 17
      );
    });
  });
  const getAffiche = (cre) => {
    const creneauDate = parseISO(cre);
    creneauDate.setHours(creneauDate.getHours());
    return creneauDate;
  };

  return (
    <CalendarContainer>
      <Grid container spacing={2} justifyContent="center">
        {days.map((day, i) => (
          <DayColumn key={i} item xs={2.4}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "bold",
                fontSize: 12,
                fontFamily: " 'Poppins', sans-serif",
              }}
            >
              {format(day, "EEEE", { locale: fr })}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontFamily: " 'Poppins', sans-serif" }}
            >
              {format(day, "dd MMM", { locale: fr })}
            </Typography>
            {creneauxParJour[i] && creneauxParJour[i].length === 0 ? (
              <Typography variant="caption">—</Typography>
            ) : (
              creneauxParJour[i]
                .filter(
                  (creneau, index) => i !== 0 || isUpcomingTimeSlot(creneau)
                )
                .map((creneau, index) => (
                  <TimeSlotButton
                    key={index}
                    variant="outlined"
                    onClick={() => {
                      handleOpenModal();
                      setselectedCre({
                        start: creneau.start,
                        end: creneau.end,
                      });
                    }}
                  >
                    {format(getAffiche(creneau.start), "HH:mm")}
                  </TimeSlotButton>
                ))
            )}
            <FormModal
              open={isModalOpen}
              handleClose={handleCloseModal}
              startTime={selectedCre.start}
              endTime={selectedCre.end}
              expertEmail={expertEmail}
            />
          </DayColumn>
        ))}
      </Grid>
    </CalendarContainer>
  );
};

export default Calendar;
