import React, { useState, useEffect } from "react";
import SeminaireCard from "../component/SeminaireCard";
import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import defaultSeminaires from "../component/defaultSeminaire";
import FiltreForm from "../component/FiltreForm";

const SeminaireGridPage = () => {
  const [seminaires, setSeminaires] = useState(defaultSeminaires);
  const { searchTerms, selectedSubdomains } = useSelector(
    (state) => state.filters
  );

  const formations = useSelector((state) => state.formations);

  useEffect(() => {
    // console.log("liste");
    // console.log(formations.formations);
    setSeminaires(formations.formations);
  }, [formations.formations]);

  const filteredSeminaires = seminaires.filter((seminaire) => {
    const matchesKeyword =
      !searchTerms.keyword ||
      seminaire.title
        .toLowerCase()
        .includes(searchTerms.keyword.toLowerCase()) ||
      seminaire.description
        .toLowerCase()
        .includes(searchTerms.keyword.toLowerCase());
  
    const matchesSociety =
      !searchTerms.society ||
      seminaire.host.toLowerCase().includes(searchTerms.society.toLowerCase());
  
    // Log pour vérifier ce qui se passe avec les formers et searchTerms.expert
    console.log("Formers: ", seminaire.formers);
    console.log("SearchTerms Expert: ", searchTerms.expert);
  
    const matchesExpert =
      !searchTerms.expert ||
      (Array.isArray(seminaire.formers) &&
        seminaire.formers.some((former) => {
          const matches =
            typeof former === "object" &&
            former.name &&
            former.name.toLowerCase().includes(searchTerms.expert.toLowerCase());
          console.log(`Former Name: ${former.name}, Matches: ${matches}`);
          return matches;
        }));
  
    const matchesSubdomains =
      selectedSubdomains.length === 0 ||
      selectedSubdomains.every((subdomain) =>
        seminaire.keywords.includes(subdomain)
      );
  
    return (
      matchesKeyword && matchesSociety && matchesExpert && matchesSubdomains
    );
  });
  

  return (
    <Box style={{ minHeight: "100vh", paddingBottom: "30px" }}>
      <Navbar />
      <Banner />
      <FiltreForm />
      <Grid
        container
        spacing={4}
        justifyContent="center"
        style={{
          marginTop: "20px",
          width: "100%",
          maxWidth: 1280,
          margin: "auto",
          paddingRight: "30px",
        }}
      >
        {filteredSeminaires ? (
          filteredSeminaires.map((seminaire, i) => {
            return (
              <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                <SeminaireCard Seminaire={seminaire} />
              </Grid>
            );
          })
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};

export default SeminaireGridPage;
