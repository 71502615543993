import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Navbar from "../component/Navbar";
import TrendingSection from "../component/TrendingSection";
import Banner from "../component/Banner";
import Button from "../component/StyledBouton";
import Defaultevents from "../component/defaultSeminaire";
import SliderEvent from "../component/SliderEvent";

import { useSelector, useDispatch } from "react-redux";
import { fetchFormations } from "../store/formationsReducer";

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  // const { formations, loading, error } = useSelector(
  //   (state) => state.formations
  // );

  const formations = useSelector((state) => state.formations);

  useEffect(() => {
    // console.log("landing");
    // console.log(formations.formations);
    setEvents(formations.formations);
  }, [formations.formations]);

  return (
    <Box>
      <Navbar />
      <Box>
        <Banner />
      </Box>
      <Box
        sx={{
          height: "auto",
          paddingBottom: isMobile ? "25px" : "50px",
          backgroundColor: "#F1E6E6",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "90%" : "80%",
            margin: "auto",
            paddingTop: "30px",
          }}
        >
          <Typography variant="h5" sx={{ color: "black" }}>
            Les évènements à découvrir
          </Typography>
        </Box>

        <SliderEvent events={events} />
      </Box>
      {/******  end slider part **************************************/}
      {/****** start TrendingS ection **************************************/}
      <TrendingSection />
      <Box sx={{ p: isMobile ? 2 : 4, backgroundColor: "#F1E6E6" }}>
        <Grid
          container
          spacing={isMobile ? 4 : 9}
          sx={{ width: "90%", margin: "auto" }}
        >
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              image={
                events[0]
                  ? events[0].images[0]
                  : "https://www.acadys.com/wp-content/uploads/2018/11/formation-S2-fin-2018-1024x735-1.png"
              }
              alt="Image représentative"
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              sx={{ marginBottom: "20px" }}
              dangerouslySetInnerHTML={{
                __html: events[0]
                  ? events[0].title
                  : "Seminaires conferences certification",
              }}
            >
              {/* Le contenu HTML va ici, mais il sera ignoré car dangerouslySetInnerHTML est utilisé */}
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                width: isMobile ? "100%" : "75%", // Responsive width
                textAlign: "justify",
                marginBottom: "40px",
              }}
              dangerouslySetInnerHTML={{
                __html: events[0]
                  ? events[0].description
                  : "Inscrivez vous aux prochains Seminaires acadys & Capgemini institut pour ne rien rater des dernières avancées en terme d'IA. Plus de 5 000 webinars pour vous former, faire de la veille, trouver des solutions. En 3 étapes, devenez membre et accédez gratuitement à l'intégralité des webinars conçus et animés pour vous par des experts.",
              }}
            ></Typography>
            <Button variant="contained" color="secondary">
              S'inscrire
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: isMobile ? "100%" : "90%",
                textAlign: "justify",
                marginBottom: "20px",
              }}
              dangerouslySetInnerHTML={{
                __html: events[1]
                  ? events[1].title
                  : "Les opportunités de l’IA générative (les 20 et 21 février 2024)",
              }}
            ></Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                width: isMobile ? "100%" : "75%", // Responsive width
                textAlign: "justify",
                marginBottom: "40px",
              }}
              dangerouslySetInnerHTML={{
                __html: events[1]
                  ? events[1].description
                  : "Ce séminaire vise à sensibiliser les comités de direction et les directions générales aux enjeux clés du numérique et aux nouveaux modèles économiques qui en découlent, tels que ceux utilisés par les GAFAM. Parmi les thèmes abordés : les concepts de l’IA, les différentes solutions du marché, les cas d’usage, les domaines et les métiers impactés, les nouveaux services, la mise en œuvre opérationnelle, les enjeux éthiques et juridiques, ainsi que les perspectives.",
              }}
            ></Typography>
            <Button variant="contained" color="secondary">
              S'inscrire
            </Button>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <CardMedia
              component="img"
              image={
                events[1]
                  ? events[1].images[0]
                  : "https://digitalonomics.fr/wp-content/uploads/2023/08/acadys.png"
              }
              alt="Une autre image représentative"
              sx={{
                width: "100%", // Ensure it takes up the container width
                height: "auto", // Adjust height automatically
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LandingPage;
