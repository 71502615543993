import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // basic Swiper styles
import "swiper/css/navigation"; // module styles for navigation
import { Navigation } from "swiper"; // import necessary modules for navigation
import EventIcon from "@mui/icons-material/Event";

const DatesSlider = ({ dates }) => {
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={2}
      navigation={false}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      {dates.map((d, index) => (
        <SwiperSlide key={index}>
          <Chip
            icon={<EventIcon style={{ color: "white", width: "18px" }} />}
            label={
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.63rem",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {`${d.startDate} - ${d.endDate}`}
              </Typography>
            }
            variant="filled"
            color="secondary"
            sx={{
              color: "white",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              display: "flex",
              alignItems: "center",
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default DatesSlider;
