import React, { useState } from "react";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import SliderKeys from "./SliderKeys";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import DatesSlider from "./DatesSlider";
import ExpertAvatarSlider from "./ExpertsAvatarSlider";

const ExpandableTextContainer = styled("div")(({ theme, isexpanded }) => ({
  maxHeight: isexpanded ? "none" : "68px",
  overflow: "hidden",
  marginBottom: "10px",
  position: "relative",
  paddingBottom: isexpanded ? "0" : theme.spacing(2),
}));

const ExpandButton = styled("span")(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,
  position: "absolute",
  bottom: 0,
  right: 0,
  backgroundColor: "white",
}));

const TrendingCard = styled(Card)(({ theme }) => ({
  borderRadius: "5px",
  boxShadow: "2px 2px 20px #0D0729",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[5],
  },
  width: "100%",
  height: "550px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    height: "600px",
  },
  [theme.breakpoints.up("md")]: {
    height: "540px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "530px",
  },
}));
const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1,
  overflow: "hidden",
});
const StyledCardMedia = styled(CardMedia)({
  height: "140px",
});
const TextContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: "hiden",
  height: "fit-content",
  maxHeight: "calc(620px - 140px - 60px)",
}));

const SeminaireCard = ({ Seminaire }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const getFormersInPairs = (formers) => {
    const pairs = [];
    for (let i = 0; i < formers.length; i += 2) {
      pairs.push(formers.slice(i, i + 2));
    }
    return pairs;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const formerPairs = getFormersInPairs(Seminaire.formers);

  return (
    <TrendingCard>
      <CardActionArea
        onClick={() => {
          navigate("/seminaire/" + Seminaire._id);
        }}
      >
        <StyledCardMedia
          component="img"
          image={Seminaire.images[0]}
          alt={Seminaire.title}
        />
        <ContentWrapper>
          <TextContent>
            <Box sx={{ height: "105px", overflow: "hidden" }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  fontSize: {
                    xs: "clamp(8px, 4vw, 14px)",
                    sm: "clamp(10px, 4.5vw, 16px)",
                    md: "clamp(12px, 5vw, 18px)",
                    lg: "clamp(14px, 5.5vw, 20px)",
                    xl: "clamp(16px, 6vw, 22px)",
                  },
                }}
              >
                {Seminaire.title}
              </Typography>
            </Box>
            <Box sx={{ height: "100px", overflow: "hidden" }}>
              <ExpandableTextContainer isexpanded={isExpanded ? 1 : 0}>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: Seminaire.description }}
                />
              </ExpandableTextContainer>
            </Box>
            <Box sx={{ height: "20px", overflow: "hidden" }}>
              <Typography sx={{ fontSize: "0.75rem", color: "text.secondary" }}>
                Organisé par : {Seminaire.host}
              </Typography>
            </Box>
            <Box sx={{ height: "50px", overflow: "hidden" }}>
              <DatesSlider dates={Seminaire.date} />
            </Box>
            <Box sx={{ height: "50px", overflow: "hidden" }}>
              <ExpertAvatarSlider formerPairs={Seminaire.formers} />
            </Box>
            <Box sx={{ height: "50px", overflow: "hidden" }}>
              <SliderKeys keywords={Seminaire.keywords} />
            </Box>
          </TextContent>
        </ContentWrapper>
      </CardActionArea>
    </TrendingCard>
  );
};

export default SeminaireCard;
