import React, { createContext, useContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { useAlert } from "./AlertContext"; // Assurez-vous d'adapter le chemin d'import selon votre structure
import { useLocation } from "react-router-dom"; // Importez useLocation de react-router-dom
import { CHAT_url } from "./GlobalVariables";
const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const socket = useRef(null);
  const socketUrl = CHAT_url;
  const { showAlert } = useAlert();
  const location = useLocation(); // Utilisez useLocation pour accéder au chemin actuel

  useEffect(() => {
    socket.current = io.connect(socketUrl, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 500,
      timeout: 6000,
    });

    const isInChatbotPage = location.pathname.startsWith("/Chatbot/");

    socket.current.on("connect", () => {
      if (isInChatbotPage) { // Affichez l'alerte uniquement si nous sommes dans une page /Chatbot/
        showAlert("Connexion au serveur réussie !", "success");
      }
      socket.current.emit("register_user", { userId: "Acadys_ChatBot" });
    });

    socket.current.on("disconnect", () => {
      if (isInChatbotPage) {
        showAlert("Déconnecté du serveur !", "warning");
      }
    });

    socket.current.on("connect_error", (error) => {
      if (isInChatbotPage) {
        showAlert("Erreur de connexion au serveur !", "error");
      }
    });

    socket.current.on("reconnect_failed", () => {
      if (isInChatbotPage) {
        showAlert("La reconnexion a échoué après plusieurs tentatives !", "error");
      }
    });

    socket.current.on("event", (msg) => {
      if (isInChatbotPage) {
        showAlert("Event recu " + msg.message, "info");
      }
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [showAlert, location.pathname]); // Ajoutez location.pathname dans les dépendances d'effet pour réagir aux changements de chemin

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};
