import defaultDomaines from "./defaultDomaines";
const defaultSeminaires = [
  {
    id: 0,
    title: "Intelligence Artificielle et conseil",
    description: "Intelligence Artificielle et conseil | Opportunités et défis",
    category: "IAG",
    host: "Acadys",
    place: "",
    date: [
      {
        startDate: "12 Janv. 2024",
        endDate: "23 Janv. 2024",
      },
    ],
    schedules: [
      "Premier jour : 9h30 - 17h30",
      "Deuxième jour : 9h00 - 17h00",
      "Déjeuner : 12h30 - 14h00",
    ],
    formers: ["Georges Bressange", "Sadadou mohamed", "Guilhem Vermorel"],
    price: "",
    keywords: [
      "Exploration de données",
      "Simulation de scénarios",
      "Optimisation de processus",
      "Génération d'idées",
      "Analyse de tendances technologiques",
      "Études de faisabilité",
      "Innovation ouverte",
      "IAG",
    ],
    program: [""],
    goals: [""],
    images: [
      "https://www.zabala.fr/wp-content/uploads/2023/11/intelligence-artificielle-et-conseil-1200x675.jpg",
    ],
    docs: [""],
  },
  {
    id: 1,
    title: "Les États-Unis en tête de la course à l'intelligence artificielle",
    description:
      "Les États-Unis en tête de la course à l'intelligence artificielle",
    category: "IAG",
    host: "Acadys",
    place: "",
    date: [
      {
        startDate: "15 Févr. 2024",
        endDate: "16 Févr. 2024",
      },
      {
        startDate: "22 Févr. 2024",
        endDate: "23 Févr. 2024",
      },
    ],
    schedules: [""],
    formers: ["Georges Bressange", "Sadadou mohamed"],
    price: "",
    keywords: [
      "Exploration de données",
      "Simulation de scénarios",
      "Optimisation de processus",
      "Génération d'idées",
      "Analyse de tendances technologiques",
      "Études de faisabilité",
      "Innovation ouverte",
      "IAG",
    ],
    program: [""],
    goals: [""],
    images: [
      "https://images.radio-canada.ca/q_auto,w_700/v1/ici-info/16x9/cerveau-machine-humain-intelligence-artificielle-elon-musk-neuralink.jpg",
    ],
    docs: [""],
  },
  {
    id: 2,
    title: "Qu'est-ce que l'Intelligence Artificielle (IA) ?",
    description: "Qu'est-ce que l'Intelligence Artificielle (IA) ?",
    category: "IAG",
    host: "Acadys",
    place: "",
    date: [
      {
        startDate: "20 Mars 2024",
        endDate: "21 Mars 2024",
      },
    ],
    schedules: [""],
    formers: ["Georges Bressange", "Sadadou mohamed"],
    price: "",
    keywords: ["IAG", "RAG"],
    program: [""],
    goals: [""],
    images: [
      "https://actualiteinformatique.fr/wp-content/uploads/2019/04/Intelligence-Artificielle.jpg",
    ],
    docs: [""],
  },
  {
    id: 3,
    title: "Prompt Engineering pour le Générateur de Langage",
    description:
      "Un guide détaillé sur la façon de guider les modèles de langage avec des prompts.",
    category: "Prompt engineering",
    host: "Acadys",
    place: "",
    date: [
      {
        startDate: "27 Avril 2024",
        endDate: "28 Avril 2024",
      },
    ],
    schedules: [""],
    formers: ["Georges Bressange", "Sadadou mohamed"],
    price: "",
    keywords: ["IAG", "Prompt engineering", "RAG"],
    program: [""],
    goals: [""],
    images: [
      "https://assets-global.website-files.com/622611a2b23d0e6b270fba81/657b3acee4460aa92a77911b_Product%20GIFs%20(1200%20%C3%97%20630px)%20(22).png",
    ],
    docs: [""],
  },
  {
    id: 4,
    title: "Créer des Prompts Efficaces pour l'IA",
    description:
      "Apprenez à formuler des prompts qui maximisent la performance des modèles d'IA.",
    category: "Prompt engineering",
    host: "",
    place: "",
    date: [{ startDate: "", endDate: "" }],
    schedules: [""],
    formers: ["Georges Bressange", "Sadadou mohamed"],
    price: "",
    keywords: [
      "Exploration de données",
      "Simulation de scénarios",
      "Optimisation de processus",
      "Génération d'idées",
      "Analyse de tendances technologiques",
      "Études de faisabilité",
      "Innovation ouverte",
      "IAG",
    ],
    program: [""],
    goals: [""],
    images: [
      "https://pic.digital/wp-content/uploads/2023/06/prompts-ai-art-generator_picdigital.jpg",
    ],
    docs: [""],
  },
  {
    id: 5,
    title: "Les Secrets du Prompt Engineering",
    description:
      "Découvrez les techniques avancées pour interagir avec les modèles d'IA.",
    category: "Prompt engineering",
    host: "",
    place: "",
    date: [{ startDate: "", endDate: "" }],
    schedules: [""],
    formers: ["Georges Bressange", "Sadadou mohamed"],
    price: "",
    keywords: [
      "Exploration de données",
      "Simulation de scénarios",
      "Optimisation de processus",
      "Génération d'idées",
      "Analyse de tendances technologiques",
      "Études de faisabilité",
      "Innovation ouverte",
      "IAG",
    ],
    program: [""],
    goals: [""],
    images: [
      "https://media.licdn.com/dms/image/D5612AQGzlrIIxjxgkg/article-cover_image-shrink_600_2000/0/1693718536890?e=2147483647&v=beta&t=Nzt0IW41OZFiABftUbWFNu0eNWKkeozRsnvqFz1jQkQ",
    ],
    docs: [""],
  },
];

export default defaultSeminaires;
