import React, { useState } from "react";
import { Card, CardContent, Typography, Dialog, DialogContent, Button } from "@mui/material";
import { Document, Page } from 'react-pdf';
import PdfPreview from "./PdfPreview";
import PdfPreviewModal from "./PdfPreviewModal";

function getLimitedDescription(description) {
  const maxLength = 70;
  
  // Vérifie si la description dépasse la longueur maximale et la tronque si nécessaire
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + "...";
  }
  
  return description;
}

const PdfCard = ({ pdf }) => {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <>
      <div onClick={handleClickOpen} style={{ textDecoration: 'none' }}>
        <Card
          style={{
            width: '80vw', // 90% de la largeur de l'écran
            maxWidth: '400px', // Limite maximale de largeur
            height: '75vh', // 75% de la hauteur de l'écran
            maxHeight: '600px', // Limite maximale de hauteur
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            margin: 'auto', // Centrer la carte
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PdfPreview pdfUrl={pdf.url} />
          </div>
          <CardContent
            style={{
              flexShrink: 0,
              textAlign: 'center',
              padding: '16px',
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ fontSize: '1.2rem', marginBottom: '8px', minHeight: '50px' }}
            >
              {pdf.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: '0.875rem', minHeight: '40px' }}
            >
              {getLimitedDescription(pdf.description)}
            </Typography>
          </CardContent>
        </Card>
      </div>

      <PdfPreviewModal
        pdfUrl={pdf.url}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default PdfCard;
