// userDuck.js

import axios from "axios";
import { API_url } from "../GlobalVariables";
// Action Types
const LOGIN_BEGIN = "user/LOGIN_BEGIN";
const LOGIN_SUCCESS = "user/LOGIN_SUCCESS";
const LOGIN_FAILURE = "user/LOGIN_FAILURE";
const LOGOUT = "user/LOGOUT";
const FETCH_PROFILE_SUCCESS = "user/FETCH_PROFILE_SUCCESS";

const REGISTER_BEGIN = "user/REGISTER_BEGIN";
const REGISTER_SUCCESS = "user/REGISTER_SUCCESS";
const REGISTER_FAILURE = "user/REGISTER_FAILURE";

// Action Creators
export const loginBegin = () => ({
  type: LOGIN_BEGIN,
});

export const loginSuccess = (token, user) => {
  console.log("loginSuccess");
  localStorage.setItem("authToken", token);
  localStorage.setItem("user", JSON.stringify(user));
  console.log("localStorageSuccess");
  console.log(token);

  return {
    type: LOGIN_SUCCESS,
    payload: { token, user },
  };
};

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: { error },
});

export const logout = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("user");
  return {
    type: LOGOUT,
  };
};

export const fetchProfileSuccess = (profile) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: { profile },
});

export const registerBegin = () => ({
  type: REGISTER_BEGIN,
});

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: { user },
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: { error },
});

// Reducer
const initialState = {
  token: null,
  user: null,
  profile: null,
  loading: false,
  error: null,
  message: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        loading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
      };
    default:
      return state;
  }
}

// Thunk Actions
export function login(credentials) {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await axios.post(`${API_url}/signIn`, credentials);
      const { token, user } = response.data;
      dispatch(loginSuccess(token, user));
    } catch (error) {
      let errorMessage = "Une erreur est survenue. Veuillez réessayer.";

      if (error.response) {
        console.log("Erreur de réponse du serveur :", error.response.data);

        if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        dispatch(loginFailure(errorMessage));
      } else if (error.request) {
        errorMessage =
          "Le serveur ne répond pas. Veuillez vérifier votre connexion Internet et réessayer.";
        dispatch(loginFailure(errorMessage));
      } else {
        dispatch(loginFailure(errorMessage));
      }
    }
  };
}

export function fetchUserProfile(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_url}/user/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(fetchProfileSuccess(response.data));
    } catch (error) {
      // Handle error, e.g., by dispatching a failure action
    }
  };
}

// Thunk Action
export function register(userData) {
  return async (dispatch) => {
    dispatch(registerBegin());
    try {
      const response = await axios.post(`${API_url}/auth/register`, userData);
      const { user } = response.data;
      dispatch(registerSuccess(user));
      // Vous pourriez dispatcher loginSuccess ici si vous voulez connecter l'utilisateur directement après l'inscription
    } catch (error) {
      dispatch(registerFailure(error.toString()));
    }
  };
}
