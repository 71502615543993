import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/navigation"; // Module styles for navigation
import { Navigation } from "swiper"; // Import necessary modules for navigation
import PersonIcon from "@mui/icons-material/Person";

const FormersSlider = ({ formerPairs }) => {
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={2}
      navigation={false}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      {formerPairs.map((pair, index) => (
        <SwiperSlide key={index}>
          <Chip
            sx={{ fontSize: "0.75rem" }}
            key={index}
            avatar={<PersonIcon />}
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: "0.76rem", color: "text.secondary" }}
              >
                {pair.name}
              </Typography>
            }
            variant="outlined"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FormersSlider;
