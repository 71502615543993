import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, Typography, Dialog, DialogContent, Button, Box, Grid } from "@mui/material";
import MovieIcon from "@mui/icons-material/Movie";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const VideoCard = ({ video }) => {
  const [open, setOpen] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null); // Reference for the container to go full screen

  const quizzes = video.quizzes || []; // Use the quizzes from the video object

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsQuizCompleted(false);
    setCurrentQuiz(0);
    setCorrectAnswers(0);
  };

  const handleAnswer = (index) => {
    if (index === quizzes[currentQuiz].answer) {
      setCorrectAnswers(correctAnswers + 1);
    }
    setCurrentQuiz(currentQuiz + 1);
    setShowQuiz(false);
    videoRef.current.play();
  };

  const handleVideoPause = () => {
    if (!showQuiz && currentQuiz < quizzes.length) {
      setShowQuiz(true);
    }
  };

  const handleVideoEnd = () => {
    setIsQuizCompleted(true);
  };

  const handleTimeUpdate = () => {
    if (currentQuiz < quizzes.length && videoRef.current.currentTime >= (currentQuiz + 1) * 10) {
      videoRef.current.pause();
    }
  };
  

  // Function to trigger full-screen mode for the container
  const handleFullScreenToggle = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    } else if (containerRef.current.mozRequestFullScreen) {
      containerRef.current.mozRequestFullScreen(); // Firefox
    } else if (containerRef.current.webkitRequestFullscreen) {
      containerRef.current.webkitRequestFullscreen(); // Chrome, Safari, Opera
    } else if (containerRef.current.msRequestFullscreen) {
      containerRef.current.msRequestFullscreen(); // IE/Edge
    }
  };

  // Event listener to handle the full-screen change
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullScreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullScreenChange);
    };
  }, []);

  function getLimitedDescription(description) {
    const maxLength = 70;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  return (
    <>
      <div onClick={handleClickOpen} style={{ textDecoration: 'none' }}>
        <Card
          style={{
            width: '80vw',
            maxWidth: '400px',
            height: '75vh',
            maxHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            margin: 'auto',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MovieIcon style={{ fontSize: '5rem' }} color="primary" />
          </div>
          <CardContent
            style={{
              flexShrink: 0,
              textAlign: 'center',
              padding: '16px',
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ fontSize: '1.2rem', marginBottom: '8px', minHeight: '50px' }}
            >
              {video.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: '0.875rem', minHeight: '40px' }}
            >
              {getLimitedDescription(video.description)}
            </Typography>
          </CardContent>
        </Card>
      </div>

      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          style: {
            maxHeight: '80vh', // Limit the maximum height to 80% of the viewport height
          },
        }}
      >
        <DialogContent>
          {/* Score and Progress Display */}
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
            <Grid item>
              <Typography variant="h6">
                Score: {correctAnswers} / {quizzes.length}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Questions restantes: {quizzes.length - currentQuiz}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h6" align="center" gutterBottom>
            {video.title}
          </Typography>
          {video.mp4_file ? (
            <div ref={containerRef} style={{ position: 'relative' }}>
              {/* Centered button to trigger full-screen, hidden in full-screen mode */}
              {!isFullScreen && (
                <Box display="flex" justifyContent="center" marginBottom={2}>
                  <Button
                    onClick={handleFullScreenToggle}
                    variant="contained"
                    style={{ padding: '10px 20px' }}
                  >
                    Passer en Plein Écran
                  </Button>
                </Box>
              )}

              <video 
                ref={videoRef}
                controls 
                controlsList="nofullscreen" // Disable the native full-screen control
                style={{ width: '100%' }}
                onPause={handleVideoPause}
                onEnded={handleVideoEnd}
                onTimeUpdate={handleTimeUpdate}
              >
                <source src={video.mp4_file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {showQuiz && currentQuiz < quizzes.length && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    zIndex: 1000,
                  }}
                >
                  <HelpOutlineIcon color="primary" style={{ fontSize: '3rem', marginBottom: '10px' }} />
                  <Typography variant="h5" gutterBottom>{quizzes[currentQuiz].question}</Typography>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {quizzes[currentQuiz].options.map((option, index) => (
                      <Button 
                        key={index} 
                        onClick={() => handleAnswer(index)}
                        variant="outlined" 
                        style={{ margin: '10px', minWidth: '200px' }}
                      >
                        {option}
                      </Button>
                    ))}
                  </Box>
                </div>
              )}
              {isQuizCompleted && (
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                  Quiz terminé! Réponses correctes: {correctAnswers} / {quizzes.length}
                </Typography>
              )}
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              La vidéo n'est pas encore disponible.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoCard;
