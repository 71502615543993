import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Typography, Avatar } from '@mui/material';

const ExpertCardWrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
}));

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginBottom: theme.spacing(2),
}));

const ExpertName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
}));

const ExpertTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ExpertCardMini = ({ name, title, imageUrl }) => {
  return (
    <ExpertCardWrapper>
      <CustomAvatar src={imageUrl} alt={name} />
      <CardContent>
        <ExpertName variant="subtitle1">{name}</ExpertName>
        <ExpertTitle variant="body2">{title}</ExpertTitle>
      </CardContent>
    </ExpertCardWrapper>
  );
};

export default ExpertCardMini;
