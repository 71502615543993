const initialState = {
  searchTerms: { keyword: "", society: "", expert: "" },
  selectedSubdomains: [],
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_TERMS":
      return {
        ...state,
        searchTerms: action.payload,
      };
    case "SET_SELECTED_SUBDOMAINS":
      return {
        ...state,
        selectedSubdomains: action.payload,
      };
    case "ADD_SELECTED_SUBDOMAIN":
      return {
        ...state,
        selectedSubdomains: [...state.selectedSubdomains, action.payload],
      };
    case "REMOVE_SELECTED_SUBDOMAIN":
      return {
        ...state,
        selectedSubdomains: state.selectedSubdomains.filter(
          (subdomain) => subdomain !== action.payload
        ),
      };
    case "RESET_FILTERS":
      return initialState; // Réinitialise l'état aux valeurs par défaut
    default:
      return state;
  }
};

export default filtersReducer;

// Actions
export const setSearchTerms = (searchTerms) => ({
  type: "SET_SEARCH_TERMS",
  payload: searchTerms,
});

export const setSelectedSubdomains = (subdomains) => ({
  type: "SET_SELECTED_SUBDOMAINS",
  payload: subdomains,
});

export const addSelectedSubdomain = (subdomain) => ({
  type: "ADD_SELECTED_SUBDOMAIN",
  payload: subdomain,
});

export const removeSelectedSubdomain = (subdomain) => ({
  type: "REMOVE_SELECTED_SUBDOMAIN",
  payload: subdomain,
});

// Nouvelle action pour réinitialiser les filtres
export const resetFilters = () => ({
  type: "RESET_FILTERS",
});
