const defaultDomaines = {
    // "Marketing et Publicité": [
    //   "Création de contenu",
    //   "Publicités personnalisées",
    //   "Génération de noms de marque et de slogans",
    //   "Optimisation de contenu",
    //   "Email marketing personnalisé",
    //   "Analyse de sentiments",
    //   "Conception graphique",
    //   "Vidéos et animations",
    //   "Chatbots pour le service client",
    //   "Analyse de tendances",
    // ],
    "Développement de Produits": [
      "Prototypage rapide",
      "Design génératif",
      "Personnalisation de produits",
      "Analyse prédictive",
      "Conception assistée par IA",
      "Gestion de l'innovation",
      "R&D accélérée",
      "Tests de produits virtuels",
      "Maintenance prédictive",
      "Optimisation de la chaîne d'approvisionnement",
    ],
    // "Gestion des Ressources Humaines": [
    //   "Recrutement automatisé",
    //   "Formation et développement",
    //   "Analyse des performances",
    //   "Engagement des employés",
    //   "Planification des effectifs",
    //   "Analyse de la culture d'entreprise",
    //   "Gestion des talents",
    //   "Assistance RH virtuelle",
    //   "Prévision des besoins en recrutement",
    //   "Bien-être des employés",
    // ],
    // "Finance et Comptabilité": [
    //   "Prévisions financières",
    //   "Détection de fraude",
    //   "Gestion des risques",
    //   "Automatisation de la comptabilité",
    //   "Conseil fiscal automatisé",
    //   "Analyse de crédit",
    //   "Gestion de portefeuille",
    //   "Trading algorithmique",
    //   "Audit automatisé",
    //   "Budget prédictif",
    // ],
    // "Opérations et Logistique": [
    //   "Optimisation de la chaîne d'approvisionnement",
    //   "Planification de la production",
    //   "Gestion des entrepôts",
    //   "Logistique prédictive",
    //   "Maintenance préventive",
    //   "Gestion de la qualité",
    //   "Gestion des retours",
    //   "Simulation d'opérations",
    //   "Gestion des risques opérationnels",
    //   "Analyse des coûts opérationnels",
    // ],
    "Service Client": [
      "Chatbots et assistants virtuels",
      "Personnalisation du service client",
      "Analyse de feedback client",
      "Gestion des connaissances",
      "Détection de l'humeur du client",
      "Automatisation des réponses",
      "Suivi des interactions client",
      "Analyse des tendances de service",
      "Formation des agents de service",
      "Gestion des crises",
    ],
    // "Sécurité Informatique": [
    //   "Détection de malware",
    //   "Analyse comportementale",
    //   "Prévention des intrusions",
    //   "Gestion des vulnérabilités",
    //   "Authentification biométrique",
    //   "Cryptographie avancée",
    //   "Analyse de risque",
    //   "Formation à la sécurité",
    //   "Gestion des incidents",
    //   "Conformité réglementaire",
    // ],
    "R&D": [
      "Exploration de données",
      "Simulation de scénarios",
      "Optimisation de processus",
      "Génération d'idées",
      "Analyse de tendances technologiques",
      "Développement de nouveaux matériaux",
      "Études de faisabilité",
      "Modélisation et simulation",
      "Innovation ouverte",
      "Propriété intellectuelle",
    ],
    "Divertissement et Médias": [
      "Production de contenu",
      "Personnalisation de l'expérience utilisateur",
      "Animation et effets visuels",
      "Génération de musique",
      "Édition de vidéo",
      "Création de jeux vidéo",
      "Analyse de préférences",
      "Virtualisation d'événements",
      "Création de scénarios",
      "Réalité augmentée et virtuelle",
    ],
    IA: [
      "IAG",
      "Fine tunning",
      "Deep learning",
      "Machine learning",
      "Data mining",
      "NLP",
      "RAG",
      "Transfert learning",
      "Data processing",
      "Prompt engineering",
    ],
  };
  
  export default defaultDomaines;
  