import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Chip,
  Stack,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Calendar from "./Calendar.js";

const ExpertCardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column", // Changez la direction en colonne pour les petits écrans
  width: "90%", // Assurez-vous qu'il occupe toute la largeur disponible sur les petits écrans
  margin: theme.spacing(2),
  padding: "5px",
  boxShadow: "1px 1px 15px #C9C9C9",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row", // Disposition en ligne pour les écrans moyens et plus grands
    width: 700, // Largeur fixe pour les écrans plus larges
  },
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%", // Prend toute la largeur sur les petits écrans
  [theme.breakpoints.up("sm")]: {
    width: "40%", // Largeur ajustée pour les écrans moyens et plus grands
  },
}));

const CalendarContainer = styled(Box)(({ theme }) => ({
  width: "100%", // Prend toute la largeur sur les petits écrans
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    width: "60%", // Largeur ajustée pour les écrans moyens et plus grands
  },
}));

const ExpertCard = ({ expert }) => (
  <ExpertCardContainer>
    <InfoContainer>
      <Box
        sx={{
          height: 250, // Hauteur fixe pour le conteneur
          width: "100%", // Largeur du conteneur
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Centrer le contenu verticalement
          overflow: "hidden", // Masquer l'excès d'image
          borderRadius: "0px 0px 50px 50px",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: "100%", // Largeur maximale de l'image
            objectFit: "contain", // Gardez l'aspect ratio de l'image
          }}
          image={expert.photo}
          alt={`Photo de ${expert.nom}`}
        />
      </Box>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "35%",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ fontFamily: " 'Poppins', sans-serif" }} gutterBottom variant="h6" component="div">
            {expert.nom}
          </Typography>
          <Typography sx={{ fontSize: 12, color: "text.secondary", fontFamily: " 'Poppins', sans-serif" }}>
            {expert.titre}
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            flexWrap: "wrap",
            gap: 1,
            marginTop: 2,
            height: "180px",
            overflow: "hidden",
            fontFamily: " 'Poppins', sans-serif",
          }}
        >
          {expert.motsCles.map((motCle, index) => (
            <Chip key={index} label={motCle} sx={{ fontSize: 12, fontFamily: " 'Poppins', sans-serif" }} />
          ))}
        </Stack>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body2" sx={{ fontSize: 13, fontFamily: " 'Poppins', sans-serif" }}>Contact</Typography>
          <Typography sx={{ fontSize: 12, fontFamily: " 'Poppins', sans-serif", color: "text.secondary" }}>
            {expert.mail}
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: " 'Poppins', sans-serif", color: "text.secondary" }}>
            {expert.phone}
          </Typography>
        </Box>
      </CardContent>
    </InfoContainer>
    <CalendarContainer>
      <Typography variant="h6" gutterBottom>
        Disponibilités
      </Typography>
      <Calendar creneaux={expert.disponibilites} expertEmail={expert.mail} />
    </CalendarContainer>
  </ExpertCardContainer>
);

export default ExpertCard;
