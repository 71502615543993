import React from "react";
import { styled } from "@mui/system";
import { Typography, Box, Chip, Stack } from "@mui/material";
import { isPast, parse } from "date-fns";
import { fr } from "date-fns/locale";

const StyledBanner = styled(Box)(({ theme, image }) => ({
  position: "relative",
  color: "white",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "570px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "200px",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 0,
  },
}));

const BannerContent = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const BannerDate = styled(Box)(({ theme, isPast }) => ({
  display: "inline-flex",
  flexDirection: "column",
  backgroundColor: isPast ? "#cccccc" : "#9C27B0", // Conditional background color
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0),
  },
  opacity: isPast ? 0.5 : 1, // Reduced opacity for past dates
}));

const DatesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "fit-content",
    margin: "auto",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.125rem",
  },
}));

const Banner = ({ title, dates, image, schedules }) => {
  const formatDateRange = (startDate, endDate) => {
    const start = parse(startDate, "dd/MM/yyyy", new Date());
    const end = parse(endDate, "dd/MM/yyyy", new Date());
  
    const options = { day: "numeric", month: "long", year: "numeric" };
    const locale = fr; // Using the French locale as you specified
  
    // Format the whole date for end first to use it in different condition checks
    const formattedEnd = end.toLocaleDateString(locale, options);
  
    // Check if start and end are in the same month and year
    if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
      // If true, we only show the day range followed by the common month and year
      const formattedStartDay = start.getDate();
      return `${formattedStartDay} - ${formattedEnd}`;
    } else {
      // If false, we show the full date range
      const formattedStart = start.toLocaleDateString(locale, options);
      return `${formattedStart} - ${formattedEnd}`;
    }
  };
  

  const formatSchedule = (schedules) => {
    return schedules.map((schedule, index) => {
      const matches = schedule.match(/(\d{1,2})h(\d{2}) - (\d{1,2})h(\d{2})/);
      const startHour = matches[1].padStart(2, "0");
      const startMinute = matches[2];
      const endHour = matches[3].padStart(2, "0");
      const endMinute = matches[4];
      return {
        key: index,
        label: `${startHour}:${startMinute} - ${endHour}:${endMinute}`,
      };
    });
  };

  const formattedSchedules = formatSchedule(schedules);

  return (
    <StyledBanner image={image}>
      <BannerContent>
        <Box>
          <DatesContainer>
            {dates.map((d, index) => {
              const dateIsPast = isPast(
                parse(d.endDate, "dd/MM/yyyy", new Date())
              );
              return (
                <BannerDate key={index} isPast={dateIsPast}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: "0.575rem", md: "0.8rem" } }}
                  >
                    {formatDateRange(d.startDate, d.endDate)}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="wrap"
                    sx={{ mt: 1 }}
                  >
                    {formattedSchedules.slice(0, 2).map((schedule) => (
                      <Chip
                        key={schedule.key}
                        label={schedule.label}
                        variant="outlined"
                        sx={{
                          fontSize: { xs: "0.575rem", md: "0.8rem" },
                          color: dateIsPast ? "#666666" : "white", // Conditional text color
                          borderColor: dateIsPast ? "#999999" : "white", // Conditional border color
                        }}
                      />
                    ))}
                  </Stack>
                </BannerDate>
              );
            })}
          </DatesContainer>
          <ResponsiveTypography variant="h4" component="h1" sx={{ mt: 2 }}>
            {title}
          </ResponsiveTypography>
        </Box>
      </BannerContent>
    </StyledBanner>
  );
};

export default Banner;
